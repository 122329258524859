import React from "react";
import {ResponsiveRadialBar} from "@nivo/radial-bar";
import {RadialBarSerie} from "@nivo/radial-bar/dist/types/types";
import GenericBarChart from "./GenericBars";
import {BarChart} from "@mui/x-charts";
import Box from "@mui/material/Box";

export function StyledRadial(props: {data: RadialBarSerie[], vformat: (value: number) => string, min: number, max: number}) {
    return <ResponsiveRadialBar
        data={props.data}
        valueFormat={props.vformat}
        cornerRadius={2}
        maxValue={1}
        margin={{ top: 0, right: 0, bottom: -100, left: 0 }}
        enableTracks={true}
        // enableLabels={true}
        circularAxisOuter={null}
        labelsRadiusOffset={0.4}
        enableCircularGrid={false}
        enableRadialGrid={false}
        // radialAxisStart={null}
        startAngle={0}
        endAngle={90}
        // legends={undefined}
        tooltip={(props) => {
            return <div
                style={{
                    padding: 12,
                    background: '#222222',
                    color: props.bar.color
                }}
            >
                <strong>
                    {props.bar.formattedValue} in {props.bar.data.x}
                </strong>
            </div>
        }}
        theme={{
            labels:{
              text: {
                  fill: '#ffffff'
              }
            },
            tooltip: {
                wrapper: {
                    color: 'white',
                    background: 'blue',
                },
                container: {
                    color: 'white',
                    background: '#3a9896',
                },
                tableCell: {
                    color: 'black',
                }
            },
            legends: {
                text: {
                    fontSize: 18,
                    fill: '#ffffff',
                }
            },
            axis: {
                ticks: {
                    text: {
                        fill: '#ffffff',
                        fontSize: 10,
                    },
                },

            },
        }}
    />
}


export function WinratesRadial(props: {values: number[], labels: string[]}) {
    const data = props.labels.map((label, i) => {
        return { id: `${label} : ${(props.values[i]*100).toFixed(1)}%`,
                data :[ { x: label, y:props.values[i]}]
                }
    })
    let vformat = (value: number) => `${(value*100).toFixed(1)}%`
    return <StyledRadial data={data} vformat={vformat} max={1} min={0}/>
}

export function GoldDiffRadial(props: {values: number[], labels: string[]}){
    const maxValue = 6000;
    const minValue = -6000;

    const data = props.labels.map((label, i) => {
        return { id: `${label} : ${props.values[i] > 0 ? '+' : ''}${props.values[i]?.toFixed(0)}`,
            data :[ { x: label, y: ( ( props.values[i] - minValue) ) / (maxValue - minValue)}]
        }
    })
    let vformat = (value: number) => `${value > 0.5 ? '+' : ''}${(( value * (maxValue - minValue) ) + minValue).toFixed(0)}`
    return <StyledRadial data={data} vformat={vformat} max={maxValue} min={minValue}/>
}

export function ValueRadialChart(props: {values: number[], labels: string[], minValue: number, maxValue: number}){
    const data = props.labels.map((label, i) => {
        return { id: `${label} : ${props.values[i]?.toFixed(2)}`,
            data :[ { x: label, y: ( ( props.values[i] - props.minValue) ) / (props.maxValue - props.minValue)}]
        }
    })
    let vformat = (value: number) => `${(( value * (props.maxValue - props.minValue) ) + props.minValue).toFixed(2)}`
    return <StyledRadial data={data} vformat={vformat} max={props.maxValue} min={props.minValue}/>
}

export function DurationRadialChart(props: {values: number[], labels: string[]}){
    const minValue = 1200000000;
    const maxValue = 2100000000;
    const data = props.labels.map((label, i) => {
        return { id: `${label} : ${props.values[i] ? new Date(props.values[i] / 1000).toISOString().substring(14, 19) : 0}`,
            data :[ { x: label, y:  props.values[i] ? ( ( props.values[i] - minValue) ) / (maxValue - minValue) : 0}]
        }
    })
    let vformat = (r: number) => {
        const rec_v = (( r * (maxValue - minValue) ) + minValue)
        return new Date(rec_v / 1000).toISOString().substring(14, 19)
    }
    return <StyledRadial data={data} vformat={vformat} max={maxValue} min={minValue}/>
}
