import React, { useEffect, useState } from "react";
import { Grow, Paper, Stack, styled } from "@mui/material";
import Box from "@mui/material/Box";
import FarsightApi from "../../ApiWrapper";
import PickturnGrid, { RolePickturnGrid } from "../datagrids/Competitive/PickturnGrid";
import { loss_color, win_color } from "../../static_vars";
import { targets, useReferentialContext } from "../../Context";
import TableDisplayGrid, { rowStyle } from "../datagrids/shared/TableDisplayGrid";

export default function DraftingMetagameBlock(props: { paramObject: any; target: targets }) {
    return (
        <Stack
            spacing={2}
            direction={"column"}
            sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Grow in={true} style={{ transformOrigin: "0 -50 100" }} {...{ timeout: 2000 }}>
                <Box>
                    <SideWinrates paramObject={props.paramObject} target={props.target} />
                </Box>
            </Grow>
            <Grow in={true} style={{ transformOrigin: "0 -50 -50" }} {...{ timeout: 2000 }}>
                <Stack direction={"row"} spacing={1} sx={{ width: "100%"}}>
                    <RoleCPEfficiency paramObject={{ ...props.paramObject, side: "blue" }} target={props.target} />
                    <RoleCPEfficiency paramObject={{ ...props.paramObject }} target={props.target} />
                    <RoleCPEfficiency paramObject={{ ...props.paramObject, side: "red" }} target={props.target} />
                </Stack>
            </Grow>
            <Stack
                direction="row"
                sx={{
                    height: "1150px",
                    justifyContent: "center",
                    display: 'flex',
                    width: "100%",
                }}
                spacing={2}
            >
                <Grow in={true} style={{ transformOrigin: "0 -50 -50" }} {...{ timeout: 2000 }}>
                    <Box sx={{ width: "50%" }}>
                        <PickturnSummary paramObject={{ ...props.paramObject }} target={props.target} />
                    </Box>
                </Grow>
                <Grow in={true} style={{ transformOrigin: "0 -50 -50" }} {...{ timeout: 2000 }}>
                    <Box sx={{ width: "50%" }}>
                        <PickturnRoleSummary paramObject={{ ...props.paramObject }} target={props.target} />
                    </Box>
                </Grow>
            </Stack>
            <Stack direction="row" sx={{ height: "1150px", justifyContent: "center" }}>
                <BanturnSummary paramObject={{ ...props.paramObject }} target={props.target} />
            </Stack>
        </Stack>
    );
}

export function PickturnSummary(props: { paramObject: any; target: targets }) {
    const [rows, setRows] = useState({
        B1: [],
        "R1-2": [],
        "B2-3": [],
        R3: [],
        R4: [],
        "B4-5": [],
        R5: [],
    });
    const api = useReferentialContext().farsightApi.getSummariesApi(props.target);
    useEffect(() => {
        setRows({
            B1: [],
            "R1-2": [],
            "B2-3": [],
            R3: [],
            R4: [],
            "B4-5": [],
            R5: [],
        });
        const request_parameters = {
            ...props.paramObject,
            groupby: "pick_rotation,champion",
            metrics: ["performance", "relative_performance", "games", "winrate", "cp_frequency"].toString(),
            index_on: "pick_rotation",
            ordering: "-games",
        };

        api.aggregate(request_parameters).then((result: any) => {
            console.log(result);
            console.log(result["B1"]);
            if (result["B1"] || result["R1-2"]) setRows(result);
        });
    }, [props.paramObject.watermark]);
    return (
        <Grow in={true} style={{ transformOrigin: "0 -50 100" }} {...{ timeout: 4000 }}>
            <Stack
                direction={"row"}
                // component="fieldset"
                sx={{
                    height: "100%",
                    justifyContent: "center",
                    width: "100%",
                    borderRadius: 2,
                }}
                spacing={1}
            >
                {/*<legend>Champions by pick order</legend>*/}
                <Stack
                    sx={{
                        height: "100%",
                        justifyContent: "center",
                        width: "100%",
                    }}
                    spacing={1}
                >
                    <PickturnGrid data={rows["B1"]} label={"B1"} />
                    <PickturnGrid data={rows["B2-3"]} label={"B2-3"} />
                    <PickturnGrid data={rows["B4-5"]} label={"B4-5"} />
                </Stack>
                <Stack
                    sx={{
                        height: "100%",
                        justifyContent: "center",
                        width: "100%",
                    }}
                    spacing={1}
                >
                    <PickturnGrid data={rows["R1-2"]} label={"R1-2"} />
                    <PickturnGrid data={rows["R3"]} label={"R3"} />
                    <PickturnGrid data={rows["R4"]} label={"R4"} />
                    <PickturnGrid data={rows["R5"]} label={"R5"} />
                </Stack>
            </Stack>
        </Grow>
    );
}

export function PickturnRoleSummary(props: any) {
    const [rows, setRows] = useState({
        B1: [],
        "R1-2": [],
        "B2-3": [],
        R3: [],
        R4: [],
        "B4-5": [],
        R5: [],
    });
    const api = useReferentialContext().farsightApi.getSummariesApi(props.target);
    useEffect(() => {
        setRows({
            B1: [],
            "R1-2": [],
            "B2-3": [],
            R3: [],
            R4: [],
            "B4-5": [],
            R5: [],
        });
        const request_parameters = {
            ...props.paramObject,
            groupby: "pick_rotation,role",
            metrics: ["performance", "relative_performance", "games", "winrate", "cp_frequency"].toString(),
            index_on: "pick_rotation",
            ordering: "-games",
        };

        api.aggregate(request_parameters).then((result: any) => {
            const rotations = ["B1","R1-2","B2-3","R3","R4",'B4-5',"R5"];
            for(const i_i in rotations){
                const i = rotations[i_i];
                let total_games = 0
                for(const y in result[i]){
                    total_games += result[i][y].games
                }
                for(const y in result[i]){
                    result[i][y].frequency = result[i][y].games / total_games
                }
            }
            if (result["B1"] || result["R1-2"]) setRows(result);
        });
    }, [props.paramObject.watermark]);

    return (
        <Grow in={true} style={{ transformOrigin: "0 -50 100" }} {...{ timeout: 5000 }}>
            <Stack
                direction={"row"}
                // component="fieldset"
                sx={{
                    height: "100%",
                    justifyContent: "center",
                    width: "100%",
                    borderRadius: 2,
                }}
                spacing={1}
            >
                {/*<legend>Roles by pick order</legend>*/}
                <Stack
                    sx={{
                        height: "100%",
                        justifyContent: "center",
                        width: "100%",
                    }}
                    spacing={1}
                >
                    <RolePickturnGrid data={rows["B1"] ? rows["B1"] : []} label={"B1"} />
                    <RolePickturnGrid data={rows["B2-3"] ? rows["B2-3"] : []} label={"B2-3"} />
                    <RolePickturnGrid data={rows["B4-5"] ? rows["B4-5"] : []} label={"B4-5"} />
                </Stack>
                <Stack
                    sx={{
                        height: "100%",
                        justifyContent: "center",
                        width: "100%",
                    }}
                    spacing={1}
                >
                    <RolePickturnGrid data={rows["R1-2"] ? rows["R1-2"] : []} label={"R1-2"} />
                    <RolePickturnGrid data={rows["R3"] ? rows["R3"] : []} label={"R3"} />
                    <RolePickturnGrid data={rows["R4"] ? rows["R4"] : []} label={"R4"} />
                    <RolePickturnGrid data={rows["R5"] ? rows["R5"] : []} label={"R5"} />
                </Stack>
            </Stack>
        </Grow>
    );
}

export function BanturnSummary(props: any) {
    const [rows, setRows] = useState({
        "Blue Bans 1-3": [],
        "Red Bans 1-3": [],
        "Blue Bans 4-5": [],
        "Red Bans 4-5": [],
    });
    const api = useReferentialContext().farsightApi.getSummariesApi(props.target);
    useEffect(() => {
        const request_parameters = {
            ...props.paramObject,
            groupby: "ban_rotation,champion",
            metrics: ["games", "winrate"].toString(),
            index_on: "ban_rotation",
            ordering: "-games",
        };

        api.aggregate(request_parameters).then((result: any) => {
            console.log(result);
            console.log(result["Blue Bans 1-3"]);
            if (result["Blue Bans 1-3"] || "Red Bans 1-3") setRows(result);
        });
    }, [props]);
    return (
        <Stack direction={"row"}>
            <Stack
                sx={{
                    height: "1000px",
                    justifyContent: "center",
                    width: "600px",
                }}
            >
                <PickturnGrid data={rows["Blue Bans 1-3"]} label={"Blue Bans 1-3"} />
                <PickturnGrid data={rows["Blue Bans 4-5"]} label={"Blue Bans 4-5"} />
            </Stack>
            <Stack
                sx={{
                    height: "1000px",
                    justifyContent: "center",
                    width: "600px",
                }}
            >
                <PickturnGrid data={rows["Red Bans 1-3"]} label={"Red Bans 1-3"} />
                <PickturnGrid data={rows["Red Bans 4-5"]} label={"Red Bans 4-5"} />
            </Stack>
        </Stack>
    );
}

export function RoleCPEfficiency(props: { paramObject: any; target: targets }) {
    const request_parameters = {
        ...props.paramObject,
        groupby: "role",
        metrics: ["performance", "relative_performance", "games", "winrate"].toString(),
        aggregates: "participant_id__avg",
        ordering: "participant_id__avg",
        is_cp: 1,
    };
    const api = useReferentialContext().farsightApi.getSummariesApi(props.target);

    return (
        <Stack direction={"row"} sx={{ height: "308px", justifyContent: "center", width: "100%" }}>
            <TableDisplayGrid request={() => api.aggregate(request_parameters)} display_all={true} rowStyle={rowStyle.none} sortBy={"role"} paramObject={request_parameters} hideFooter={true} />
        </Stack>
    );
}

const TabCell = styled(Paper)(() => ({
    backgroundColor: "#333333",
    color: "#FAF9F6",
    borderRadius: 0,
    textTransform: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    width: "100px",
    height: "40px",
})) as typeof Paper;

export function SideWinrates(props: { paramObject: any; target: targets }) {
    const [sides, setSides] = useState({
        Blue: { games: 0, winrate: 0 },
        Red: { games: 0, winrate: 0 },
    } as any);
    const api = useReferentialContext().farsightApi.getSummariesApi(props.target);

    useEffect(() => {
        const request_parameters = {
            ...props.paramObject,
            groupby: "side",
            metrics: ["performance", "relative_performance", "games", "winrate"].toString(),
            index_on: "side",
            ordering: "side",
            format: "json",
        };
        api.aggregate(request_parameters).then((result: any) => {
            setSides(result);
        });
    }, [props.paramObject.watermark]);

    return (
        <Stack direction={"row"}>
            <Stack direction={"column"}>
                <TabCell
                    sx={{
                        backgroundColor: win_color,
                        color: "#FAF9F6",
                        width: "200px",
                    }}
                >
                    Blue side
                </TabCell>
                <Stack direction={"row"}>
                    <TabCell
                        sx={{
                            backgroundColor: win_color,
                            width: "100px",
                        }}
                    >
                        {sides["Blue"] && sides["Blue"][0] ? sides["Blue"][0].games + " games" : 0 + " games"}
                    </TabCell>
                    <TabCell
                        sx={{
                            backgroundColor: win_color,
                            width: "100px",
                        }}
                    >
                        {sides["Blue"] && sides["Blue"][0]
                            ? sides["Blue"][0].winrate.toLocaleString("en", {
                                  style: "percent",
                              }) + " WR"
                            : "No games"}
                    </TabCell>
                </Stack>
            </Stack>
            <Stack direction={"column"}>
                <TabCell sx={{ backgroundColor: loss_color, width: "200px" }}>Red side</TabCell>
                <Stack direction={"row"}>
                    <TabCell
                        sx={{
                            backgroundColor: loss_color,
                            width: "100px",
                        }}
                    >
                        {sides["Red"] && sides["Red"][0] ? sides["Red"][0].games + " games" : 0 + " games"}
                    </TabCell>
                    <TabCell
                        sx={{
                            backgroundColor: loss_color,
                            width: "100px",
                        }}
                    >
                        {sides["Red"] && sides["Red"][0]
                            ? sides["Red"][0].winrate.toLocaleString("en", {
                                  style: "percent",
                              }) + " WR"
                            : "No games"}
                    </TabCell>
                </Stack>
            </Stack>
        </Stack>
    );
}
