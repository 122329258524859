import {Card, CardHeader, CircularProgress, Select, Stack, Typography} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import {Bar, Line, Pie} from "react-chartjs-2";
import React, {useEffect, useState} from "react";
import {BarChart} from "@mui/x-charts";
import {Champion, targets, useReferentialContext} from "../../Context";
import PaginatedStack from "../Utilities/PaginatedStack";
import ChampionIcon from "../Icons/ChampionIcon";
import {StatInStack} from "../Styles/Texts";
import formatters from "../Styles/Formatters";


export default function RatingsExplanationChart(props: {championRating: any}) {
    let ratingColumns = [
        'relativeKills',
        'relativeDeaths',
        'relativeDamages',
        'relativeDamageTaken',
        'relativeGolds',
        'relativeDmgHealed',
        'relativeDmgMitigated',
        'relativeWardsPlaced',
        'relativeWardsKilled',
        'relativeLevel',
        'relativeCCtime'
    ];
    // ratingColumns = ratingColumns.sort((a: string, b: string ) => Math.abs(props.championRating[`${b}_corr`]) - Math.abs(props.championRating[`${a}_corr`]));
    const [chartType, setCharType] = useState('Pie');
    const data = {
        labels: ratingColumns.map((col: string) => `${col}`),
        datasets: [
            {
                label: 'Correlations',
                data: ratingColumns.map((col: string) => chartType === 'Pie' ?  Math.abs(props.championRating[`${col}_corr`]) : props.championRating[`${col}_corr`]),
                backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)", "rgba(255, 206, 86, 0.2)", "rgba(75, 192, 192, 0.2)", "rgba(153, 102, 255, 0.2)", "rgba(255, 159, 64, 0.2)"],
                borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)", "rgba(75, 192, 192, 1)", "rgba(153, 102, 255, 1)", "rgba(255, 159, 64, 1)"],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        lineTension: 0.3,
    };

    return (
        <Stack direction={'row'}
               sx={{
                   height: "100%",
                   width: "100%",
                   alignItems: "center",
                   justifyContent: "center",
                   display: "flex",
               }}
        >
            <BarChart barLabel={(item, context) => {
                        return item.value ? `${(item.value).toFixed(1)}` : '';
                    }} series={
                        [{data: ratingColumns.map(col => props.championRating[`${col}_corr`]), label:'Correlation'}]
                    }
                      margin={{left: 120}}
                      layout={'horizontal'}
                      yAxis={[{ data: ratingColumns, scaleType: 'band', max:0.6 }]}
            />
        </Stack>
    )
}

export function RatingComparisonDetails(props: {paramObject: any, target: targets, champion: string, fullRatings?: any []}){
    const ctx = useReferentialContext();
    const [comparisons, setComparisons] = useState([] as any[]);


    useEffect(() => {
        ctx.farsightApi.getSummariesApi(props.target).rr_comparison({
            rr_compare: props.champion,
            ...props.paramObject,
            champion: undefined
        }).then(r => setComparisons(r.sort((a: any, b: any) => (b.comparison.games - a.comparison.games))));
    }, [props.paramObject.watermark, props.champion, props.target]);

    return <PaginatedStack
            direction={"row"}
            sx={{
                width: "100%",
                height:'200px',
                alignItems:'center',
                justifyContent:'space-around',
                display:'flex',
            }}
        >
        {
            comparisons.length > 0 ? comparisons.map(c => {
                const rr = props.fullRatings ? props.fullRatings.filter(r_o => r_o.champion === c.compared_to)[0] : undefined;
                return <Card sx={{height:'100%', width:'180px'}}>
                <Stack direction={'column'} sx={{width:'100%', height:'100%', padding:1}} spacing={1}>
                    <ChampionIcon champion={c.compared_to} role={props.paramObject.role} sx={{height:'40px', width:'40px'}}/>
                    <StatInStack label={'Games'} value={c.comparison.games} format={formatters.integer}/>
                    <StatInStack label={'Winrate'} value={c.comparison.winrate} format={formatters.percent}/>
                    {rr ? <StatInStack label={`RR`} value={rr.rel_rate}/> : <></>}
                    <StatInStack label={`Comparison`} value={c.rr} format={formatters.delta}/>
                </Stack>
            </Card> }) : [1,1,1,1,1].map(n => <CircularProgress sx={{width:'180px'}}/>)
        }
    </PaginatedStack>
}