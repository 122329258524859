import {targets, useReferentialContext} from "../../Context";
import {Fade, Link, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import {SoloQAccounts} from "../../farsight-api";


export default function DeeplolIcon(props: {account: SoloQAccounts, sx? :any}){
    return <Tooltip title={"deeplol"} followCursor={true} sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    <Link href={`https://www.deeplol.gg/summoner/${props.account.server.replace("1", "")}/${props.account.account_name}`} target="_blank">
                        <Box
                            component={"img"}
                            src="../icons/deeplol.ico"
                            alt="Deeplol"
                            sx={props.sx ? props.sx : {
                                width: "25px",
                                height: "25px",
                            }}
                        />
                    </Link>
                </Tooltip>
}