import {Box, Fade, Grow} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel, {a11yProps} from "../../components/tabs_switch/tabs_panel";
import SynergiesMatchups from "../../components/visu_blocks/ChampionMatchups";
import ChampionItems from "../../components/visu_blocks/ChampionItems";
import ChampionRunesSimple from "../../components/visu_blocks/ChampionRunesSimple";
import ChampionRunesFull from "../../components/visu_blocks/ChampionRunesFull";
import React, {useEffect, useState} from "react";
import WindowContainer from "../../components/layout/WindowContainer";
import {Champion, targets, useReferentialContext} from "../../Context";
import {League} from "../../farsight-api";
import FilterSet from "../../components/selectors/Filterset";
import Stack from "@mui/material/Stack";
import GamesHistory from "../../components/datagrids/shared/GamesHistory";
import VariableDistribution from "../../components/Charts/VariableDistribution";
import {ChampionStatSummary} from "../../components/visu_blocks/StatsSummary";
import MetricDistribution from "../../components/Charts/MetricDistribution";
import RatingsExplanationChart, {RatingComparisonDetails} from "../../components/Charts/RatingExplanationChart";


export function ChampionsDetailsCompetitive() {
    const context = useReferentialContext();
    const [patches, setPatches] = useState(context.selectedPatches as string[]);
    const [leagues, setLeagues] = useState(context.selectedLeagues as League[]);
    const [champion, setChampion] = useState(context.selectedChampion as Champion);
    const [role, setRole] = useState(context.selectedRole);
    const [picktype, setPicktype] = useState(null as null | boolean);

    const [paramObject, setParamObject] = useState({} as any);

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet
                paramObject={paramObject}
                setParamObject={setParamObject}
                target={targets.competitive}
                updateContext={true}
                leagues={leagues}
                setLeagues={setLeagues}
                patches={patches}
                setPatches={setPatches}
                role={role}
                setRole={setRole}
                champion={champion}
                setChampion={setChampion}
                picktype={picktype}
                setPicktype={setPicktype}
            />
            {champion && role && patches.length > 0 && leagues.length > 0 ? <ChampionDetails paramObject={paramObject} target={targets.competitive} /> : <></>}
        </WindowContainer>
    );
}

export function ChampionsDetailsSoloQ() {
    const context = useReferentialContext();
    const [patches, setPatches] = useState(context.selectedPatches as string[]);
    const [servers, setServers] = useState(["EUW1", "KR"]);
    const [champion, setChampion] = useState(context.selectedChampion as Champion);
    const [role, setRole] = useState("BOT_LANE");

    const [paramObject, setParamObject] = useState({} as any);

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet
                paramObject={paramObject}
                setParamObject={setParamObject}
                target={targets.soloq}
                updateContext={true}
                servers={servers}
                setServers={setServers}
                patches={patches}
                setPatches={setPatches}
                role={role}
                setRole={setRole}
                champion={champion}
                setChampion={setChampion}
            />
            {champion && role && patches.length > 0 && servers.length > 0 ? <ChampionDetails paramObject={paramObject} target={targets.soloq} /> : <></>}
        </WindowContainer>
    );
}

export function ChampionsDetailsScrims() {
    const context = useReferentialContext();
    const [patches, setPatches] = useState(context.selectedPatches as string[]);
    const [champion, setChampion] = useState(context.selectedChampion as Champion);
    const [role, setRole] = useState("BOT_LANE");

    const [paramObject, setParamObject] = useState({} as any);

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet
                paramObject={paramObject}
                setParamObject={setParamObject}
                target={targets.scrims}
                updateContext={true}
                patches={patches}
                setPatches={setPatches}
                role={role}
                setRole={setRole}
                champion={champion}
                setChampion={setChampion}
            />
            {champion && role && patches.length > 0 ? <ChampionDetails paramObject={paramObject} target={targets.scrims} /> : <></>}
        </WindowContainer>
    );
}

export function ChampionsDetailsChampionsQueue() {
    const context = useReferentialContext();
    const [patches, setPatches] = useState(context.selectedPatches as string[]);
    const [champion, setChampion] = useState(context.selectedChampion as Champion);
    const [role, setRole] = useState("BOT_LANE");

    const [paramObject, setParamObject] = useState({} as any);

    return (
        <WindowContainer direction={"column"} spacing={2}>
            <FilterSet
                paramObject={paramObject}
                setParamObject={setParamObject}
                target={targets.championsq}
                updateContext={true}
                patches={patches}
                setPatches={setPatches}
                role={role}
                setRole={setRole}
                champion={champion}
                setChampion={setChampion}
            />
            {champion && role && patches.length > 0 ? <ChampionDetails paramObject={paramObject} target={targets.championsq} /> : <></>}
        </WindowContainer>
    );
}

function ChampionDetails(props: { paramObject: any; target: targets }) {
    const paramObject = props.paramObject;
    const [tab, setTab] = useState(0);

    return (
        <Stack direction={"column"} spacing={2} sx={{ height: "100%" }}>
            <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: 2000 }}>
                <Tabs value={tab} aria-label="basic tabs example" onChange={(event, newValue) => setTab(newValue)} sx={{ height: "50px" }}>
                    <Tab sx={{fontWeight:'bold'}} label="Overview" {...a11yProps(0)} />
                    <Tab sx={{fontWeight:'bold'}} label="Matchups" {...a11yProps(1)} />
                    <Tab sx={{fontWeight:'bold'}} label="Synergies" {...a11yProps(2)} />
                    <Tab sx={{fontWeight:'bold'}} label="Items" {...a11yProps(3)} />
                    <Tab sx={{fontWeight:'bold'}} label="Runes - simple" {...a11yProps(4)} />
                    <Tab sx={{fontWeight:'bold'}} label="Runes - full" {...a11yProps(5)} />
                    <Tab sx={{fontWeight:'bold'}} label="History" {...a11yProps(6)} />
                    <Tab sx={{fontWeight:'bold'}} label="Distributions" {...a11yProps(7)} />
                </Tabs>
            </Grow>

            <TabPanel value={tab} index={0}>
                {props.target !== targets.scrims ? <ChampionOverview paramObject={paramObject} target={props.target}/> : <></>}
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <SynergiesMatchups paramObject={paramObject} target={props.target} type={"Matchup"} />
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <SynergiesMatchups paramObject={paramObject} target={props.target} type={"Synergy"} />
            </TabPanel>
            <TabPanel value={tab} index={3}>
                <ChampionItems paramObject={paramObject} target={props.target} />
            </TabPanel>
            <TabPanel value={tab} index={4}>
                <ChampionRunesSimple paramObject={paramObject} target={props.target} />
            </TabPanel>
            <TabPanel value={tab} index={5}>
                <ChampionRunesFull paramObject={paramObject} target={props.target} />
            </TabPanel>
            <TabPanel value={tab} index={6}>
                <Box sx={{width:'100%', height:'90%'}}>
                    <GamesHistory paramObject={paramObject} target={props.target} />
                </Box>
            </TabPanel>
            <TabPanel value={tab} index={7}>
                <MetricDistribution paramObject={paramObject} target={props.target} />
            </TabPanel>
        </Stack>
    );
}

function ChampionOverview(props: {paramObject: any, target: targets}) {
    const champion = props.paramObject.champion;
    const [statsOverview, setStatsOverview] = useState(undefined as any);
    const [ratings, setRatings] = useState([] as any[]);
    const [championRatings, setChampionRatings] = useState(undefined as any);

    const context = useReferentialContext();

    const api = context.farsightApi.getSummariesApi(props.target);

    useEffect(() => {
        const request_parameters = {
            ...props.paramObject,
            groupby: 'champion',
            metrics: ["games", "winrate", "kda", "cspm", "gd15", "xpd15", "csd15", "dpm", "gpm",
                'relativeKills', 'relativeDeaths', 'relativeDamages', 'relativeDamageTaken', 'relativeGolds', 'relativeDmgHealed', 'relativeDmgMitigated', 'relativeWardsPlaced', 'relativeWardsKilled', 'relativeLevel', 'relativeCCtime'].toString(),
            aggregates: ['relative_performance__avg','performance__avg','relative_performance__std','performance__std',
                'performance__avg__in_wins', 'performance__std__in_wins',
                'performance__avg__in_losses', 'performance__std__in_losses',
                'relative_performance__avg__in_wins', 'relative_performance__std__in_wins',
                'relative_performance__avg__in_losses', 'relative_performance__std__in_losses',
            ].toString(),
            format: "json",
            ordering: 'champion'
        };
        api.aggregate(request_parameters).then(result => {
                setStatsOverview(result ? result[0] : undefined);
            }
        );
        const ratings_request_parameters = {
            ...props.paramObject,
            target: props.target
        }
        if(props.target === targets.competitive){
            context.farsightApi.competitiveGameSummaries.champion_ratings({...ratings_request_parameters,
                champion: undefined,
                include_ally_perfs:false,
                include_presence_stats:true,
            }
            ).then((result: any) => {
                    setRatings(result);
                    // @ts-ignore
                    const cr = result.filter(r => r.champion === props.paramObject.champion)
                    setChampionRatings(cr.length > 0 ? cr[0] : undefined);
                }
            )
        }else if(props.target == targets.soloq && champion != undefined){
            setChampionRatings(undefined)
            context.farsightApi.soloqChampionsRating.list({...props.paramObject,
                champion: undefined,
                servers: props.paramObject.additional_filters.server__in.toString(),
                additional_filters:{
                    patch__in: props.paramObject.additional_filters.patch__in
                }}).then(r => {
                    const cr = r.filter(r => r.champion === props.paramObject.champion)[0]
                    setChampionRatings(cr);
                    setRatings(r);
                }
            );
        }
    }, [props.paramObject.watermark]);



    // console.log(data);
    return champion ? (
        <Fade in={true} style={{ transformOrigin: "0 100 0" }} {...{ timeout: 2000 }}>
            <Stack
                direction={"row"}
                sx={{
                    height: '90%',
                    justifyContent: "space-evenly",
                    width: "100%",
                    alignItems: "center",
                    padding: 1,
                }}
            >
                {statsOverview ? (
                    <ChampionStatSummary statsOverview={statsOverview} championRatings={championRatings} target={props.target} />
                ) : (

                        <Box
                            sx={{
                                width: "230px",
                                textJustify: "center",
                                textAlign: "center",
                            }}
                        >{`No ${champion} games for selected preset`}</Box>
                )}
                    <Stack
                        direction={"column"}
                        sx={{
                            height: "100%",
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "space-evenly",
                            width: "60%",
                        }}
                    >
                        <Box sx={{ height: "50%", width: "60%", maxWidth:'600px' }}>
                            {championRatings ? <RatingsExplanationChart championRating={championRatings}/> : <></>}
                        </Box>
                        <Box sx={{ height: "50%", width: "100%" }}>
                            {championRatings ? <RatingComparisonDetails target={props.target} paramObject={props.paramObject} champion={props.paramObject.champion} fullRatings={ratings}/>: <></>}
                        </Box>
                    </Stack>


                    <Stack
                        direction={"column"}
                        sx={{
                            height: "100%",
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "space-around",
                            width: "20%",
                        }}
                    >
                        <Box sx={{ height: "45%", width: "100%" }}>
                            {
                                statsOverview ? <VariableDistribution paramObject={props.paramObject} target={props.target}
                                                                      bucket_size={props.target !== "soloq" ? 20 : 20} min={-250} max={250}
                                                                      metric={"performance"} label={"P"}
                                                                      gaussianParameters={[
                                                                          {
                                                                              mean: statsOverview['performance__avg'],
                                                                              std: statsOverview['performance__std'],
                                                                              label: 'P Norm.',
                                                                          },
                                                                          {
                                                                              mean: statsOverview['performance__avg__in_wins'],
                                                                              std: statsOverview['performance__std__in_wins'],
                                                                              label: 'Wins',
                                                                              multiplier: statsOverview['winrate'],
                                                                              color: "rgb(100, 100, 255)"
                                                                          },
                                                                          {
                                                                              mean: statsOverview['performance__avg__in_losses'],
                                                                              std: statsOverview['performance__std__in_losses'],
                                                                              label: 'Loss',
                                                                              multiplier: 1 - statsOverview['winrate'],
                                                                              color: "rgb(255, 100, 100)"
                                                                          },
                                                                      ]}/> : <></>
                            }
                        </Box>
                        <Box sx={{ height: "45%", width: "100%" }}>
                            {statsOverview ? <VariableDistribution
                                paramObject={props.paramObject}
                                target={props.target}
                                bucket_size={props.target !== "soloq" ? 20 : 20}
                                min={-150} max={150}
                                metric={"relative_performance"} label={"RP"}
                                gaussianParameters={[
                                    {mean: statsOverview['relative_performance__avg'], std: statsOverview['relative_performance__std'], multiplier: 1, label: 'RP Norm'},
                                    {mean: statsOverview['relative_performance__avg__in_wins'], std: statsOverview['relative_performance__std__in_wins'], label: 'Wins', multiplier: statsOverview['winrate'], color:"rgb(100, 100, 255)"},
                                    {mean: statsOverview['relative_performance__avg__in_losses'], std: statsOverview['relative_performance__std__in_losses'], label: 'Loss', multiplier: 1 - statsOverview['winrate'], color:"rgb(255, 100, 100)"},
                                ]}/> : <></>}
                        </Box>
                    </Stack>

            </Stack>
        </Fade>
    ) : <></>
}

