/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
    AddAccount,
    AgenciesVariableDistribution200ResponseInner,
    PatchedPlayer,
    Player,
    PlayerAggregate,
} from "../models/index";

export interface AddPlayerAccountRequest {
    id: string;
    add_account: AddAccount;
}

export interface PlayerAggregateRequest {
    groupby: Array<string>;
    ordering: Array<string>;
    additional_filters?: object;
    agency?: string;
    aggregates?: Array<string>;
    birthdate?: string;
    contract_expires?: string;
    current_team?: string;
    domestic_league?: string;
    having?: object;
    index_on?: string;
    is_retired?: boolean;
    last_team?: string;
    lolpros_id?: string;
    metrics?: Array<string>;
    nationality?: string;
    player?: string;
    previous_names?: string;
    role?: string;
    soloq_tracked?: boolean;
}

export interface PlayerCreateRequest {
    id: string;
    player: Player;
}

export interface PlayerFieldValuesRequest {
    field: string;
    ordering: Array<string>;
    additional_filters?: object;
    agency?: string;
    birthdate?: string;
    contract_expires?: string;
    current_team?: string;
    domestic_league?: string;
    is_retired?: boolean;
    last_team?: string;
    lolpros_id?: string;
    nationality?: string;
    player?: string;
    previous_names?: string;
    role?: string;
    soloq_tracked?: boolean;
}

export interface PlayerGetRequest {
    id: string;
}

export interface PlayerListRequest {
    additional_filters?: object;
    agency?: string;
    annotations?: Array<string>;
    birthdate?: string;
    contract_expires?: string;
    current_team?: string;
    domestic_league?: string;
    is_retired?: boolean;
    last_team?: string;
    lolpros_id?: string;
    nationality?: string;
    ordering?: Array<string>;
    player?: string;
    previous_names?: string;
    role?: string;
    soloq_tracked?: boolean;
}

export interface PlayerPatchRequest {
    id: string;
    patched_player?: PatchedPlayer;
}

export interface PlayerVariableDistributionRequest {
    bucket_size: number;
    max: number;
    metric: string;
    min: number;
    additional_filters?: object;
    agency?: string;
    birthdate?: string;
    contract_expires?: string;
    current_team?: string;
    domestic_league?: string;
    is_retired?: boolean;
    last_team?: string;
    lolpros_id?: string;
    nationality?: string;
    player?: string;
    previous_names?: string;
    role?: string;
    soloq_tracked?: boolean;
}

export interface UpdatePlayerAccountGamesRequest {
    id: string;
}

/**
 *
 */
export class PlayerApi extends runtime.BaseAPI {
    /**
     */
    async addPlayerAccountRaw(
        requestParameters: AddPlayerAccountRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling addPlayerAccount().',
            );
        }

        if (requestParameters["add_account"] == null) {
            throw new runtime.RequiredError(
                "add_account",
                'Required parameter "add_account" was null or undefined when calling addPlayerAccount().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Player/item/{id}/add_account`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "POST",
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters["add_account"],
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async add_account(
        requestParameters: AddPlayerAccountRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.addPlayerAccountRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async playerAggregateRaw(
        requestParameters: PlayerAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<PlayerAggregate>>> {
        if (requestParameters["groupby"] == null) {
            throw new runtime.RequiredError(
                "groupby",
                'Required parameter "groupby" was null or undefined when calling playerAggregate().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling playerAggregate().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["agency"] != null) {
            queryParameters["agency"] = requestParameters["agency"];
        }

        if (requestParameters["aggregates"] != null) {
            queryParameters["aggregates"] = requestParameters["aggregates"];
        }

        if (requestParameters["birthdate"] != null) {
            queryParameters["birthdate"] = requestParameters["birthdate"];
        }

        if (requestParameters["contract_expires"] != null) {
            queryParameters["contract_expires"] =
                requestParameters["contract_expires"];
        }

        if (requestParameters["current_team"] != null) {
            queryParameters["current_team"] = requestParameters["current_team"];
        }

        if (requestParameters["domestic_league"] != null) {
            queryParameters["domestic_league"] =
                requestParameters["domestic_league"];
        }

        if (requestParameters["groupby"] != null) {
            queryParameters["groupby"] = requestParameters["groupby"];
        }

        if (requestParameters["having"] != null) {
            queryParameters["having"] = requestParameters["having"];
        }

        if (requestParameters["index_on"] != null) {
            queryParameters["index_on"] = requestParameters["index_on"];
        }

        if (requestParameters["is_retired"] != null) {
            queryParameters["is_retired"] = requestParameters["is_retired"];
        }

        if (requestParameters["last_team"] != null) {
            queryParameters["last_team"] = requestParameters["last_team"];
        }

        if (requestParameters["lolpros_id"] != null) {
            queryParameters["lolpros_id"] = requestParameters["lolpros_id"];
        }

        if (requestParameters["metrics"] != null) {
            queryParameters["metrics"] = requestParameters["metrics"];
        }

        if (requestParameters["nationality"] != null) {
            queryParameters["nationality"] = requestParameters["nationality"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["player"] != null) {
            queryParameters["player"] = requestParameters["player"];
        }

        if (requestParameters["previous_names"] != null) {
            queryParameters["previous_names"] =
                requestParameters["previous_names"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["soloq_tracked"] != null) {
            queryParameters["soloq_tracked"] =
                requestParameters["soloq_tracked"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Player/operations/aggregate`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async aggregate(
        requestParameters: PlayerAggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<PlayerAggregate>> {
        const response = await this.playerAggregateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async playerCreateRaw(
        requestParameters: PlayerCreateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Player>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling playerCreate().',
            );
        }

        if (requestParameters["player"] == null) {
            throw new runtime.RequiredError(
                "player",
                'Required parameter "player" was null or undefined when calling playerCreate().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Player/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "POST",
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters["player"],
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async create(
        requestParameters: PlayerCreateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Player> {
        const response = await this.playerCreateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async playerFieldValuesRaw(
        requestParameters: PlayerFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters["field"] == null) {
            throw new runtime.RequiredError(
                "field",
                'Required parameter "field" was null or undefined when calling playerFieldValues().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling playerFieldValues().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["agency"] != null) {
            queryParameters["agency"] = requestParameters["agency"];
        }

        if (requestParameters["birthdate"] != null) {
            queryParameters["birthdate"] = requestParameters["birthdate"];
        }

        if (requestParameters["contract_expires"] != null) {
            queryParameters["contract_expires"] =
                requestParameters["contract_expires"];
        }

        if (requestParameters["current_team"] != null) {
            queryParameters["current_team"] = requestParameters["current_team"];
        }

        if (requestParameters["domestic_league"] != null) {
            queryParameters["domestic_league"] =
                requestParameters["domestic_league"];
        }

        if (requestParameters["field"] != null) {
            queryParameters["field"] = requestParameters["field"];
        }

        if (requestParameters["is_retired"] != null) {
            queryParameters["is_retired"] = requestParameters["is_retired"];
        }

        if (requestParameters["last_team"] != null) {
            queryParameters["last_team"] = requestParameters["last_team"];
        }

        if (requestParameters["lolpros_id"] != null) {
            queryParameters["lolpros_id"] = requestParameters["lolpros_id"];
        }

        if (requestParameters["nationality"] != null) {
            queryParameters["nationality"] = requestParameters["nationality"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["player"] != null) {
            queryParameters["player"] = requestParameters["player"];
        }

        if (requestParameters["previous_names"] != null) {
            queryParameters["previous_names"] =
                requestParameters["previous_names"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["soloq_tracked"] != null) {
            queryParameters["soloq_tracked"] =
                requestParameters["soloq_tracked"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Player/operations/field-values`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async field_values(
        requestParameters: PlayerFieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.playerFieldValuesRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async playerGetRaw(
        requestParameters: PlayerGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Player>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling playerGet().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Player/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async get(
        requestParameters: PlayerGetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Player> {
        const response = await this.playerGetRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async playerListRaw(
        requestParameters: PlayerListRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<Player>>> {
        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["agency"] != null) {
            queryParameters["agency"] = requestParameters["agency"];
        }

        if (requestParameters["annotations"] != null) {
            queryParameters["annotations"] = requestParameters["annotations"];
        }

        if (requestParameters["birthdate"] != null) {
            queryParameters["birthdate"] = requestParameters["birthdate"];
        }

        if (requestParameters["contract_expires"] != null) {
            queryParameters["contract_expires"] =
                requestParameters["contract_expires"];
        }

        if (requestParameters["current_team"] != null) {
            queryParameters["current_team"] = requestParameters["current_team"];
        }

        if (requestParameters["domestic_league"] != null) {
            queryParameters["domestic_league"] =
                requestParameters["domestic_league"];
        }

        if (requestParameters["is_retired"] != null) {
            queryParameters["is_retired"] = requestParameters["is_retired"];
        }

        if (requestParameters["last_team"] != null) {
            queryParameters["last_team"] = requestParameters["last_team"];
        }

        if (requestParameters["lolpros_id"] != null) {
            queryParameters["lolpros_id"] = requestParameters["lolpros_id"];
        }

        if (requestParameters["nationality"] != null) {
            queryParameters["nationality"] = requestParameters["nationality"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["player"] != null) {
            queryParameters["player"] = requestParameters["player"];
        }

        if (requestParameters["previous_names"] != null) {
            queryParameters["previous_names"] =
                requestParameters["previous_names"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["soloq_tracked"] != null) {
            queryParameters["soloq_tracked"] =
                requestParameters["soloq_tracked"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Player/list`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async list(
        requestParameters: PlayerListRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<Player>> {
        const response = await this.playerListRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async playerPatchRaw(
        requestParameters: PlayerPatchRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Player>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling playerPatch().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters["Content-Type"] = "application/json";

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Player/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "PATCH",
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters["patched_player"],
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async patch(
        requestParameters: PlayerPatchRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Player> {
        const response = await this.playerPatchRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async playerVariableDistributionRaw(
        requestParameters: PlayerVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<AgenciesVariableDistribution200ResponseInner>>
    > {
        if (requestParameters["bucket_size"] == null) {
            throw new runtime.RequiredError(
                "bucket_size",
                'Required parameter "bucket_size" was null or undefined when calling playerVariableDistribution().',
            );
        }

        if (requestParameters["max"] == null) {
            throw new runtime.RequiredError(
                "max",
                'Required parameter "max" was null or undefined when calling playerVariableDistribution().',
            );
        }

        if (requestParameters["metric"] == null) {
            throw new runtime.RequiredError(
                "metric",
                'Required parameter "metric" was null or undefined when calling playerVariableDistribution().',
            );
        }

        if (requestParameters["min"] == null) {
            throw new runtime.RequiredError(
                "min",
                'Required parameter "min" was null or undefined when calling playerVariableDistribution().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["agency"] != null) {
            queryParameters["agency"] = requestParameters["agency"];
        }

        if (requestParameters["birthdate"] != null) {
            queryParameters["birthdate"] = requestParameters["birthdate"];
        }

        if (requestParameters["bucket_size"] != null) {
            queryParameters["bucket_size"] = requestParameters["bucket_size"];
        }

        if (requestParameters["contract_expires"] != null) {
            queryParameters["contract_expires"] =
                requestParameters["contract_expires"];
        }

        if (requestParameters["current_team"] != null) {
            queryParameters["current_team"] = requestParameters["current_team"];
        }

        if (requestParameters["domestic_league"] != null) {
            queryParameters["domestic_league"] =
                requestParameters["domestic_league"];
        }

        if (requestParameters["is_retired"] != null) {
            queryParameters["is_retired"] = requestParameters["is_retired"];
        }

        if (requestParameters["last_team"] != null) {
            queryParameters["last_team"] = requestParameters["last_team"];
        }

        if (requestParameters["lolpros_id"] != null) {
            queryParameters["lolpros_id"] = requestParameters["lolpros_id"];
        }

        if (requestParameters["max"] != null) {
            queryParameters["max"] = requestParameters["max"];
        }

        if (requestParameters["metric"] != null) {
            queryParameters["metric"] = requestParameters["metric"];
        }

        if (requestParameters["min"] != null) {
            queryParameters["min"] = requestParameters["min"];
        }

        if (requestParameters["nationality"] != null) {
            queryParameters["nationality"] = requestParameters["nationality"];
        }

        if (requestParameters["player"] != null) {
            queryParameters["player"] = requestParameters["player"];
        }

        if (requestParameters["previous_names"] != null) {
            queryParameters["previous_names"] =
                requestParameters["previous_names"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["soloq_tracked"] != null) {
            queryParameters["soloq_tracked"] =
                requestParameters["soloq_tracked"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Player/operations/variable-distribution`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async variable_distribution(
        requestParameters: PlayerVariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AgenciesVariableDistribution200ResponseInner>> {
        const response = await this.playerVariableDistributionRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async updatePlayerAccountGamesRaw(
        requestParameters: UpdatePlayerAccountGamesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling updatePlayerAccountGames().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/Player/item/{id}/update_accounts`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "POST",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async update_accounts(
        requestParameters: UpdatePlayerAccountGamesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.updatePlayerAccountGamesRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
