import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";
import {
    CompetitiveGameSummariesApi,
    Configuration,
    GameMetricsApi,
    GameMetrics,
    LeagueApi,
    League,
    ReferentialsApi,
    ScrimGameSummariesApi,
    TeamApi,
    Team,
    GamesDataApi,
    ScrimsDataApi,
    PlayerApi,
    Login,
    AgenciesApi,
    CompetitiveChampionRatingsApi,
    ComputesApi,
    DetailedApi,
    DownloadsApi,
    MapsApi,
    OffseasonNotesApi,
    OffSeasonShortlistApi,
    OffSeasonShortlistItemApi,
    ScrimFeedbackTicketsApi,
    ScrimSessionApi,
    SoloQAccountsApi,
    SoloQChampionRatingApi,
    WeeklySoloQChampionRatingApi,
    Player, ModelsApi, BASE_PATH, OffSeasonShortlist, CompetitiveTeamfight, CompetitiveParticipantTeamfight,
} from "./farsight-api";
import {ChampionsQueueSummariesApi} from "./farsight-api/apis/ChampionsQueueSummariesApi";
import {DraftPrepsApi} from "./farsight-api/apis/DraftPrepsApi";
import {CompetitiveTeamfightApi} from "./farsight-api/apis/CompetitiveTeamfightApi";
import {CompetitiveParticipantTeamfightApi} from "./farsight-api/apis/CompetitiveParticipantTeamfightApi";
import {UserApi} from "./farsight-api/apis/UserApi";
import {ClientOrganizationApi} from "./farsight-api/apis/ClientOrganizationApi";
import {CompetitiveTeamGameSummaryApi} from "./farsight-api/apis/CompetitiveTeamGameSummaryApi";
import {ScrimTeamGameSummaryApi} from "./farsight-api/apis/ScrimTeamGameSummaryApi";
import {SoloQGameSummariesAllApi} from "./farsight-api/apis/SoloQGameSummariesAllApi";
import {SoloqGameSummariesBR1Api} from "./farsight-api/apis/SoloqGameSummariesBR1Api";
import {SoloqGameSummariesEUN1Api} from "./farsight-api/apis/SoloqGameSummariesEUN1Api";
import {SoloqGameSummariesEUW1Api} from "./farsight-api/apis/SoloqGameSummariesEUW1Api";
import {SoloqGameSummariesTW2Api} from "./farsight-api/apis/SoloqGameSummariesTW2Api";
import {SoloqGameSummariesVN2Api} from "./farsight-api/apis/SoloqGameSummariesVN2Api";
import {SoloqGameSummariesTR1Api} from "./farsight-api/apis/SoloqGameSummariesTR1Api";
import {SoloqGameSummariesKRApi} from "./farsight-api/apis/SoloqGameSummariesKRApi";
import {SoloqGameSummariesLA1Api} from "./farsight-api/apis/SoloqGameSummariesLA1Api";
import {SoloqGameSummariesLA2Api} from "./farsight-api/apis/SoloqGameSummariesLA2Api";
import {SoloqGameSummariesME1Api} from "./farsight-api/apis/SoloqGameSummariesME1Api";
import {SoloqGameSummariesNA1Api} from "./farsight-api/apis/SoloqGameSummariesNA1Api";
import {SoloqGameSummariesOCApi} from "./farsight-api/apis/SoloqGameSummariesOCApi";
import {SoloqGameSummariesPH2Api} from "./farsight-api/apis/SoloqGameSummariesPH2Api";
import {SoloqGameSummariesRUApi} from "./farsight-api/apis/SoloqGameSummariesRUApi";
import {SoloqGameSummariesSEAApi} from "./farsight-api/apis/SoloqGameSummariesSEAApi";
import {SoloqGameSummariesSG2Api} from "./farsight-api/apis/SoloqGameSummariesSG2Api";
import {SoloqGameSummariesTH2Api} from "./farsight-api/apis/SoloqGameSummariesTH2Api";

interface AppContextProps {
    user: Login;
    farsightApi: FarsightApi;
    current_tab: number;
    setCurrentTab: React.Dispatch<React.SetStateAction<number>>;

    metricsReferential: GameMetrics[];
    selectedMetrics: GameMetrics[];
    setSelectedMetrics: React.Dispatch<React.SetStateAction<GameMetrics[]>>;

    leaguesReferential: League[];
    selectedLeagues: League[];
    setSelectedLeagues: React.Dispatch<React.SetStateAction<League[]>>;

    patchesReferential: string[];
    selectedPatches: string[];
    setSelectedPatches: React.Dispatch<React.SetStateAction<string[]>>;

    championsReferential: Champion[];
    selectedChampion: Champion | null;
    setSelectedChampion: React.Dispatch<React.SetStateAction<Champion | null>>;

    itemsReferential: Item[];

    teamsReferential: Team[];
    selectedTeam: Team | null;
    setSelectedTeam: React.Dispatch<React.SetStateAction<Team | null>>;

    playersReferential: Player[];
    selectedPlayer: Player | null;
    setSelectedPlayer: React.Dispatch<React.SetStateAction<Player | null>>;


    scrimAccountsReferential: string[],
    selectedScrimAccounts: string | null,
    setSelectedScrimAccounts: React.Dispatch<React.SetStateAction<string | null>>;

    ddragon_champions: { [id: string]: Champion };
    ddragon_items: { [id: string]: Item };
    currentTarget: targets;
    setCurrentTarget: React.Dispatch<React.SetStateAction<targets>>;
    selectedRole: string;
    setSelectedRole: React.Dispatch<React.SetStateAction<string>>;

    getMetrics: (codes: string[], target: targets) => GameMetrics[];
    defaultMetrics: (target: targets) => GameMetrics[];
}

export interface Champion {
    id: string;
    name: string;
    tags: string[];
}

export interface Item {
    id: string;
    name: string;
    tags: string[];
    gold: {
        total: number;
        purchasable: boolean;
    };
    description: string;
    maps: any;
}

export enum targets {
    competitive = "competitive",
    soloq = "soloq",
    scrims = "scrims",
    championsq = "championsq",
}

export enum soloq_servers {
    BR1 = 'BR1',
    EUN = 'EUN1',
    EUW1 = 'EUW1',
    JP1 = 'JP1',
    KR = 'KR',
    LA1 = 'LA1',
    LA2 = 'LA2',
    ME1 = 'ME1',
    NA1 = 'NA1',
    OC = 'OC',
    PH2 = 'PH2',
    RU = 'RU',
    SEA = 'SEA',
    SG2 = 'SG2',
    TH2 = 'TH2',
    TR1 = 'TR1',
    TW2 = 'TW2',
    VN2 = 'VN2',
    ALL = 'ALL'
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

export default function ReferentialContext(props: { user: Login; children: ReactNode, initTab: number }) {
    const { user } = props;
    const farsightApi = new FarsightApi(user);
    const [current_tab, setCurrentTab] = useState(props.initTab);
    const [ddragon_champions, setDdragonChampions] = useState<{
        [id: string]: Champion;
    }>({});
    const [ddragon_items, setDdragonItems] = useState<{
        [id: string]: Item;
    }>({});
    const [metricsReferential, setMetricsReferential] = useState<GameMetrics[]>([]);
    const [leaguesReferential, setLeaguesReferential] = useState<League[]>([]);
    const [patchesReferential, setPatchesReferential] = useState<string[]>([]);
    const [championsReferential, setChampionsReferential] = useState<Champion[]>([]);
    const [itemsReferential, setItemsReferential] = useState<Item[]>([]);
    const [teamsReferential, setTeamsReferential] = useState<Team[]>([]);
    const [playersReferential, setPlayersReferential] = useState<Player[]>([]);
    const [scrimAccountsReferential, setScrimAccountsReferentials] = useState<string[]>([]);

    const [selectedMetrics, setSelectedMetrics] = useState<GameMetrics[]>([]);
    const [selectedLeagues, setSelectedLeagues] = useState<League[]>([]);
    const [selectedPatches, setSelectedPatches] = useState<string[]>([]);
    const [selectedChampion, setSelectedChampion] = useState<Champion | null>(null);
    const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
    const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null);
    const [selectedScrimAccounts, setSelectedScrimAccounts] = useState<string | null>(null);
    const [currentTarget, setCurrentTarget] = useState(targets.competitive);
    const [selectedRole, setSelectedRole] = useState('BOT_LANE');

    async function fetchReferential<Option>(
        setReferentialState: React.Dispatch<React.SetStateAction<Option[]>>,
        setInitialState: React.Dispatch<React.SetStateAction<Option[] >>,
        initialStateFilter: (arg0: Option, arg1: number) => boolean,
        request: any,
    ) {
        return await request.then((result: Option[]) => {
            setReferentialState(result);
            setInitialState(result.filter(initialStateFilter));
            return result;
        });
    }

    useEffect(() => {
        fetchReferential(
            setMetricsReferential,
            setSelectedMetrics,
            (metric: GameMetrics) => metric.group === "Generic",
            farsightApi.gameMetrics.list({
                ordering: ["group", "index"],
                additional_filters: { label__gte: 0 },
            }),
        );
        fetchReferential(setLeaguesReferential, setSelectedLeagues, (league: League) => false, farsightApi.league.list({ ordering: ["group__index"] }));
        farsightApi.player.list({ ordering: ["current_team"] }).then((result) => {
            setPlayersReferential(result);
            setSelectedPlayer(result.filter((player) => player.player === "Faker")[0]);
        });
        farsightApi.team.list({ ordering: ["current_league"], additional_filters:{current_league__isnull:false} }).then((result) => {
            setTeamsReferential(result);
            setSelectedTeam(result.filter((team) => team.team_name === "T1")[0]);
        });

        fetchReferential(setPatchesReferential, setSelectedPatches, (patch: string, index: number) => index < 1, farsightApi.referentials.patches()).then((result) => {
            fetch(`https://ddragon.leagueoflegends.com/cdn/${result[0]}.1/data/en_US/champion.json`)
                .then((response) => response.json())
                .then((data) => {
                    setDdragonChampions(data.data);
                    const as_list = Object.entries(data.data).map(([key, value]) => value as Champion);
                    setChampionsReferential(as_list.sort((a, b) => b.tags[0].localeCompare(a.tags[0])));
                });

            fetch(`https://ddragon.leagueoflegends.com/cdn/${result[1]}.1/data/en_US/item.json`)
                .then((response) => response.json())
                .then((data) => {
                    setDdragonItems(data.data);
                    const as_list = Object.entries(data.data)
                        .filter(([key, value]) => !data.data[key].description.includes("ornn") && data.data[key].maps["11"])
                        .map(([key, value]) => value as Item);
                    console.log(as_list)
                    setItemsReferential(as_list.sort((a, b) => (b.tags[0] ? b.tags[0] : '').localeCompare(a.tags[0])));
                });
        });
        farsightApi.scrimGameSummaries.field_values({field: 'player', ordering: ['player']}).then(r => setScrimAccountsReferentials(r));
    }, []);

    const getMetrics = (codes: string[], target: targets) => {
        return metricsReferential.filter(m => codes.includes(m.code) && m.available_for?.includes(target)).sort((a, b) => codes.indexOf(a.code) - codes.indexOf(b.code));
    }

    const defaultMetrics = (target: targets) => {
        return getMetrics(['games','winrate','performance','relative_performance','kda','gd8','csd8','gd15','csd15','dpm_post_15'], target)
    }

    return (
        <AppContext.Provider
            value={{
                user,
                farsightApi,
                current_tab,
                setCurrentTab,
                metricsReferential,
                selectedMetrics,
                setSelectedMetrics,
                leaguesReferential,
                selectedLeagues,
                setSelectedLeagues,
                patchesReferential,
                selectedPatches,
                setSelectedPatches,
                championsReferential,
                selectedChampion,
                setSelectedChampion,
                itemsReferential,
                teamsReferential,
                setSelectedTeam,
                selectedTeam,
                playersReferential,
                setSelectedPlayer,
                selectedPlayer,
                scrimAccountsReferential,
                selectedScrimAccounts,
                setSelectedScrimAccounts,
                ddragon_champions,
                ddragon_items,
                currentTarget,
                setCurrentTarget,
                selectedRole,
                setSelectedRole,
                getMetrics,
                defaultMetrics
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
}

export const useReferentialContext = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error("useAppContext must be used within an AppProvider");
    }
    return context;
};

class FarsightApi {
    user_infos: Login;
    agencies: AgenciesApi;
    competitiveChampionsRatings: CompetitiveChampionRatingsApi;
    competitiveGameSummaries: CompetitiveGameSummariesApi;
    computes: ComputesApi;
    detailed: DetailedApi;
    download: DownloadsApi;
    gameMetrics: GameMetricsApi;
    gamesData: GamesDataApi;
    league: LeagueApi;
    maps: MapsApi;
    offSeasonNotes: OffseasonNotesApi;
    offSeasonShortlist: OffSeasonShortlistApi;
    offSeasonShortlistItem: OffSeasonShortlistItemApi;
    player: PlayerApi;
    referentials: ReferentialsApi;
    scrimFeedbackTickets: ScrimFeedbackTicketsApi;
    scrimGameSummaries: ScrimGameSummariesApi;
    scrimsData: ScrimsDataApi;
    scrimSession: ScrimSessionApi;
    soloqAccount: SoloQAccountsApi;
    soloqChampionsRating: SoloQChampionRatingApi;
    soloqGameSummaries: SoloQGameSummariesAllApi;

    soloqGameSummariesBR:SoloqGameSummariesBR1Api;
    soloqGameSummariesEUN:SoloqGameSummariesEUN1Api;
    soloqGameSummariesEUW:SoloqGameSummariesEUW1Api;
    soloqGameSummariesKR:SoloqGameSummariesKRApi;
    soloqGameSummariesLA:SoloqGameSummariesLA1Api;
    soloqGameSummariesLA2:SoloqGameSummariesLA2Api;
    soloqGameSummariesME:SoloqGameSummariesME1Api;
    soloqGameSummariesNA:SoloqGameSummariesNA1Api;
    soloqGameSummariesOC:SoloqGameSummariesOCApi;
    soloqGameSummariesPH:SoloqGameSummariesPH2Api;
    soloqGameSummariesRU:SoloqGameSummariesRUApi;
    soloqGameSummariesSEA:SoloqGameSummariesSEAApi;
    soloqGameSummariesSG:SoloqGameSummariesSG2Api;
    soloqGameSummariesTH:SoloqGameSummariesTH2Api;
    soloqGameSummariesTR:SoloqGameSummariesTR1Api;
    soloqGameSummariesTW:SoloqGameSummariesTW2Api;
    soloqGameSummariesVN:SoloqGameSummariesVN2Api;

    team: TeamApi;
    weeklySoloQChampionRating: WeeklySoloQChampionRatingApi;
    models: ModelsApi;
    championsqGameSummaries: ChampionsQueueSummariesApi;
    url: string;
    draftPreps: DraftPrepsApi;
    competitiveTeamfights: CompetitiveTeamfightApi;
    competitiveParticipantTeamfights: CompetitiveParticipantTeamfightApi;
    users: UserApi;
    clientOrganization: ClientOrganizationApi;
    competitiveTeamGameSummaries: CompetitiveTeamGameSummaryApi;
    scrimTeamGameSummaries: ScrimTeamGameSummaryApi;


    constructor(user_infos: Login) {
        const api_config = new Configuration({
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${user_infos.token}`,
            },
            // basePath: 'http://localhost:8000'
            basePath: 'https://api.farsight-alteration.com'
        });
        this.url = api_config.basePath;

        this.user_infos = user_infos;
        this.agencies = new AgenciesApi(api_config);
        this.competitiveChampionsRatings = new CompetitiveChampionRatingsApi(api_config);
        this.competitiveGameSummaries = new CompetitiveGameSummariesApi(api_config);
        this.computes = new ComputesApi(api_config);
        this.detailed = new DetailedApi(api_config);
        this.download = new DownloadsApi(api_config);
        this.gameMetrics = new GameMetricsApi(api_config);
        this.gamesData = new GamesDataApi(api_config);
        this.league = new LeagueApi(api_config);
        this.maps = new MapsApi(api_config);
        this.offSeasonNotes = new OffseasonNotesApi(api_config);
        this.offSeasonShortlist = new OffSeasonShortlistApi(api_config);
        this.offSeasonShortlistItem = new OffSeasonShortlistItemApi(api_config);
        this.player = new PlayerApi(api_config);
        this.referentials = new ReferentialsApi(api_config);
        this.scrimFeedbackTickets = new ScrimFeedbackTicketsApi(api_config);
        this.scrimGameSummaries = new ScrimGameSummariesApi(api_config);
        this.scrimsData = new ScrimsDataApi(api_config);
        this.scrimSession = new ScrimSessionApi(api_config);
        this.soloqAccount = new SoloQAccountsApi(api_config);
        this.soloqChampionsRating = new SoloQChampionRatingApi(api_config);
        this.soloqGameSummaries = new SoloQGameSummariesAllApi(api_config);

        this.soloqGameSummariesBR = new SoloqGameSummariesBR1Api(api_config);
        this.soloqGameSummariesEUN = new SoloqGameSummariesEUN1Api(api_config);
        this.soloqGameSummariesEUW = new SoloqGameSummariesEUW1Api(api_config);
        this.soloqGameSummariesKR = new SoloqGameSummariesKRApi(api_config);
        this.soloqGameSummariesLA = new SoloqGameSummariesLA1Api(api_config);
        this.soloqGameSummariesLA2 = new SoloqGameSummariesLA2Api(api_config);
        this.soloqGameSummariesME = new SoloqGameSummariesME1Api(api_config);
        this.soloqGameSummariesNA = new SoloqGameSummariesNA1Api(api_config);
        this.soloqGameSummariesOC = new SoloqGameSummariesOCApi(api_config);
        this.soloqGameSummariesPH = new SoloqGameSummariesPH2Api(api_config);
        this.soloqGameSummariesRU = new SoloqGameSummariesRUApi(api_config);
        this.soloqGameSummariesSEA = new SoloqGameSummariesSEAApi(api_config);
        this.soloqGameSummariesSG = new SoloqGameSummariesSG2Api(api_config);
        this.soloqGameSummariesTH = new SoloqGameSummariesTH2Api(api_config);
        this.soloqGameSummariesTR = new SoloqGameSummariesTR1Api(api_config);
        this.soloqGameSummariesTW = new SoloqGameSummariesTW2Api(api_config);
        this.soloqGameSummariesVN = new SoloqGameSummariesVN2Api(api_config);




        this.team = new TeamApi(api_config);
        this.weeklySoloQChampionRating = new WeeklySoloQChampionRatingApi(api_config);
        this.models = new ModelsApi(api_config);
        this.championsqGameSummaries = new ChampionsQueueSummariesApi(api_config);
        this.draftPreps = new DraftPrepsApi(api_config);
        this.competitiveTeamfights = new CompetitiveTeamfightApi(api_config);
        this.competitiveParticipantTeamfights = new CompetitiveParticipantTeamfightApi(api_config);
        this.users = new UserApi(api_config);
        this.clientOrganization = new ClientOrganizationApi(api_config);
        this.competitiveTeamGameSummaries = new CompetitiveTeamGameSummaryApi(api_config);
        this.scrimTeamGameSummaries = new ScrimTeamGameSummaryApi(api_config);
    }

    getSummariesApi(target: targets) {
        if (target === targets.competitive) return this.competitiveGameSummaries;
        if (target === targets.soloq) return this.soloqGameSummaries;
        if (target === targets.scrims) return this.scrimGameSummaries;
        if (target === targets.championsq) return this.championsqGameSummaries;
        return this.competitiveGameSummaries;
    }
    getSoloQApiByServer(server: soloq_servers){
        if (server === soloq_servers.BR1) return this.soloqGameSummariesBR;
        if (server === soloq_servers.EUN) return this.soloqGameSummariesEUN
        if (server === soloq_servers.EUW1) return this.soloqGameSummariesEUW
        if (server === soloq_servers.KR) return this.soloqGameSummariesKR
        if (server === soloq_servers.LA1) return this.soloqGameSummariesLA
        if (server === soloq_servers.LA2) return this.soloqGameSummariesLA2
        if (server === soloq_servers.ME1) return this.soloqGameSummariesME
        if (server === soloq_servers.NA1) return this.soloqGameSummariesNA
        if (server === soloq_servers.OC) return this.soloqGameSummariesOC
        if (server === soloq_servers.PH2) return this.soloqGameSummariesPH
        if (server === soloq_servers.RU) return this.soloqGameSummariesRU
        if (server === soloq_servers.SEA) return this.soloqGameSummariesSEA
        if (server === soloq_servers.SG2) return this.soloqGameSummariesSG
        if (server === soloq_servers.TH2) return this.soloqGameSummariesTH
        if (server === soloq_servers.TR1) return this.soloqGameSummariesTR
        if (server === soloq_servers.TW2) return this.soloqGameSummariesTW
        if (server === soloq_servers.VN2) return this.soloqGameSummariesVN
        if (server === soloq_servers.ALL) return this.soloqGameSummaries;
        return this.soloqGameSummaries;
    }

    getTeamSummariesApi(target: targets){
        if (target === targets.competitive) return this.competitiveTeamGameSummaries;
        if (target === targets.scrims) return this.scrimTeamGameSummaries;
        return this.competitiveTeamGameSummaries;
    }

    getDataApi(target: targets) {
        if (target === targets.competitive) return this.gamesData;
        if (target === targets.scrims) return this.scrimsData;
        return this.gamesData;
    }

    getRatingsApi(target: targets){
        if (target === targets.competitive) return this.competitiveChampionsRatings;
        if (target === targets.soloq) return this.soloqChampionsRating;
        return this.competitiveChampionsRatings;
    }

    format_parameters(props: any) {
        let params_to_string = "format=json";
        Object.entries(props).forEach(([key, value]) => {
            if (value !== null && value !== undefined) params_to_string += `&${key}=${value.toString()}`;
        });
        return params_to_string;
    }

    async download_file(endpoint: string, params: any, file_name: string) {
        const params_string = this.format_parameters(params);
        const url = `${this.url}/${endpoint}?${params_string}`;
        try {
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${this.user_infos.token}`,
                },
                redirect: "follow",
            });
            if (response.status !== 200) {
                throw new Error(`HTTP status code: ${response.status}`);
            }
            const blob = await response.blob();
            // Create a URL for the blob
            const blobUrl = window.URL.createObjectURL(blob);

            // Create a temporary <a> element to trigger the download
            const a = document.createElement("a");
            a.href = blobUrl;
            a.download = file_name; // Replace with the desired file name and extension
            a.style.display = "none";

            // Append the <a> element to the document and click it
            document.body.appendChild(a);
            a.click();

            // Remove the <a> element
            document.body.removeChild(a);

            // Clean up by revoking the blob URL
            window.URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error("Error:", error);
        }
    }
}