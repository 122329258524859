import React, {useEffect, useState} from "react";
import WindowContainer from "../../../components/layout/WindowContainer";
import SpecificGameSelector from "../../../components/selectors/Competitive/SpecificGameSelector";
import dayjs, {Dayjs} from "dayjs";
import {targets, useReferentialContext} from "../../../Context";
import FilterSet from "../../../components/selectors/Filterset";
import {
    Box,
    Chip, CSSObject,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grow, IconButton,
    Paper,
    Stack, Theme,
    Typography
} from "@mui/material";
import {ScrimFeedbackTickets, ScrimGameSummaries} from "../../../farsight-api";
import {loss_color, scrollable, win_color} from "../../../static_vars";
import {match} from "node:assert";
import {styled} from "@mui/material/styles";
import {TimestampSelect} from "../../../components/selectors/TimestampRangeSelect";
import MapDrawer from "../../../components/maps/map_drawer";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FarsightApi from "../../../ApiWrapper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MuiDrawer from "@mui/material/Drawer";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Drawer from "@mui/material/Drawer";
import {ChevronLeft, ChevronLeftRounded, ChevronRight} from "@mui/icons-material";


const openedMixin = (theme: Theme): CSSObject => ({
    width: '33%',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const MiniDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: '33%',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function GameReviewer(props: {target: targets}){
    const [selectedGame, setSelectedGames] = useState(undefined as string|undefined);
    const [from, setFrom] = useState<Dayjs>(dayjs().subtract(3, "month"));
    const [until, setUntil] = useState<Dayjs>(dayjs());
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [paramObject, setParamObject] = useState({} as any);
    const context = useReferentialContext();

    const updateSelection = (gameSelected: any[]) => {
        setSelectedGames(gameSelected.length > 0 ? gameSelected[0].game_id : undefined);
    };


    return <WindowContainer direction={'row'} spacing={2} sx={{overflowX:'hidden'}}>
        <MiniDrawer
            anchor={'left'}
            variant="permanent"
            open={drawerOpen}
        >
            <Stack direction={'column'} spacing={2} sx={{width:'100%', height:'100%', padding:1, alignItems:'flex-end', display:'flex'}}>
                <IconButton onClick={() => setDrawerOpen(!drawerOpen)} sx={{width:'40px'}}>
                    {drawerOpen? <ChevronLeft/> : <ChevronRight/>}
                </IconButton>
                <Stack direction={'column'} sx={{width:'100%', height:'90%', ml: drawerOpen ? '100px' : 0}} spacing={2}>
                    <FilterSet
                        paramObject={paramObject}
                        setParamObject={setParamObject}
                        date__gte={from}
                        setDateGte={setFrom}
                        date__lt={until}
                        setDateLt={setUntil}
                        target={props.target}
                    />
                    <SpecificGameSelector
                        team={context.teamsReferential.filter(t => t.team_name === context.user.team)[0]}
                        updateSelection={updateSelection}
                        from={from}
                        until={until} include_bayes={true} side_select={true} target={props.target} single={true}
                    />
                </Stack>

            </Stack>
        </MiniDrawer>
        {selectedGame && !drawerOpen ? <GameReviewerComponent game_id={selectedGame} target={props.target}/> : <></>}

    </WindowContainer>

}

function GameReviewerComponent(props: {game_id: string, target: targets}){
    const api = useReferentialContext().farsightApi.getSummariesApi(props.target);
    const [playerSummaries, setPlayerSummaries] = useState(undefined as any[] | undefined);
    const [gameEvents, setGameEvents] = useState([] as any);
    const [selectedPlayer, setSelectedPlayer] = useState(undefined as string | undefined);
    const [timestamp, setTimestamp] = useState(0);
    const [mapState, setMapState] = useState({
        wards: [] as any[],
        timestamp: 0,
    });
    console.log(timestamp)

    useEffect(( ) => {
        setMapState(ComputeMapState(gameEvents, timestamp));
    }, [timestamp])


    useEffect(() => {
        console.log(props.game_id);
        api.list({game:props.game_id, ordering:['participant_id']}).then(
            r => {
                console.log(r);
                setPlayerSummaries(r);
                let events = [] as any[];
                for(const i in r){
                    const summary: any = r[i];
                    events = [...events, ...summary.champion_kill_events.map((e: any) => {return {type:'CHAMPION_KILL', player:summary.player,data:e, summary:summary}})];
                    events = [...events, ...summary.building_kill_events.map((e: any) => {return {type:'BUILDING_KILL', player:summary.player,data:e, summary:summary}})];
                    events = summary.flashes_forced_events ? [...events, ...summary.flashes_forced_events.map((e: any) => {return {type:'FLASH_FORCED', player:summary.player,data:e, summary:summary}})] : events;
                    events = [...events, ...summary.monster_kill_events.map((e: any) => {return {type:'MONSTER_KILL', player:summary.player,data:e, summary:summary}})];
                    events = summary.ward_events ? [...events, ...summary.ward_events.map((e: any) => {return {type:'WARD_EVENT', player:summary.player,data:e, summary:summary}})] : events;
                }
                events.sort((a, b) => a.data.timestamp - b.data.timestamp)
                setGameEvents(events);
            }
        )
    }, [props.game_id]);

    function str_to_secs(str: string){
        const [hours, minutes, seconds] = str.split(":").map(Number);
        return dayjs
            .duration({
                hours,
                minutes,
                seconds,
            })
    }

    console.log(mapState.wards)

    return playerSummaries ? <Stack direction={'row'} sx={{width:"100%", height:'100%'}} spacing={4}>
        <Stack direction={'column'} sx={{justifyContent:'center', display:'flex', alignItems:'center', width:'50%'}} spacing={1}>
            <TimestampSelect max={str_to_secs(playerSummaries[0].duration).asSeconds()} value={timestamp} onChange={setTimestamp}/>
            <MapDrawer positions={ playerSummaries[0].position_history ? playerSummaries.map(ps => {
                return {
                    player: ps.player,
                    champion : ps.champion,
                    position : ps.position_history[timestamp]
                }
            }) : []} points={mapState.wards}/>
        </Stack>
        <Stack direction={'column'} sx={{justifyContent:'center', display:'flex', alignItems:'center', width:'50%', minWidth:'750px'}} spacing={1}>
            <Select value={selectedPlayer} onChange={(event) => setSelectedPlayer(event.target.value)} sx={{ width: "50%" }}
                    renderValue={(value: string) => (
                        <Stack direction={"row"} sx={{ justifyContent: "flex-start", width: "100%" }} spacing={2}>
                            {value}
                        </Stack>
                    )}>
                {playerSummaries.map(ps => <MenuItem value={ps.player}>
                    <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${ps.champion}.png`} sx={{mr:4}}/>
                    <Typography>{ps.player}</Typography>
                </MenuItem>)}
            </Select>
            <EventsStack events={gameEvents} selectedPlayer={selectedPlayer} setTimestamp={setTimestamp}/>
            {selectedPlayer ? <FeedbackTickets game_id={props.game_id} player={selectedPlayer} timestamp={timestamp}/> : <></>}
        </Stack>

    </Stack> : <></>
}



function EventsStack(props: {events: any[], selectedPlayer: string|undefined, setTimestamp: any}){
    console.log(
        props.events.filter(e => props.selectedPlayer ? props.selectedPlayer === e.player : false)
    )
    return <Stack
        direction={'column'}
        sx={{width: "100%",
            height: "100%",
            border: "2px solid white",
            borderRadius: 2,
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "5px",
            overflow:'auto',
            ...scrollable}} spacing={1}>
        {props.events.filter(e => props.selectedPlayer ? props.selectedPlayer === e.player : false).map(e => {
            switch (e.type){
                case 'CHAMPION_KILL':
                    return <Paper sx={{width:'100%',
                        "&:hover": {
                            cursor: "pointer",
                        },}} >
                        <Stack direction={'row'} sx={{width: '100%'}} alignItems={'center'} onClick={() => props.setTimestamp(parseInt(e.data.timestamp))}>
                            <Typography sx={{width:'21%'}}>{e.data.participant_got.toUpperCase()}</Typography>
                            <Typography sx={{width:'15%'}}>{dayjs.duration(e.data.timestamp, 'seconds').format('mm:ss')}</Typography>
                            <Stack sx={{width:'64%'}} direction={'row'} spacing={1}>
                                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${e.data.champion_killer}.png`} />
                                <Stack direction={'row'}>
                                    {e.data.champion_assists.map((c: string) =>
                                        <Box alignSelf={'center'} component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${c}.png`} />)}
                                </Stack>
                                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${e.data.champion_killed}.png`}/>
                            </Stack>

                        </Stack>
                    </Paper>
                case 'FLASH_FORCED':
                    return <Paper sx={{width:'100%',
                        "&:hover": {
                            cursor: "pointer",
                        },}} >
                        <Stack direction={'row'} sx={{width: '100%'}} alignItems={'center'} onClick={() => props.setTimestamp(parseInt(e.data.timestamp))}>
                            <Typography sx={{width:'21%'}}>{'Forced Flash'}</Typography>
                            <Typography sx={{width:'15%'}}>{dayjs.duration(e.data.timestamp, 'seconds').format('mm:ss')}</Typography>
                            <Stack sx={{width:'64%'}} direction={'row'} spacing={1}>
                                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${e.summary.champion}.png`} />
                                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${e.data.champion}.png`}/>
                            </Stack>

                        </Stack>
                    </Paper>
                case 'WARD_EVENT':
                    return <Paper sx={{width:'100%',
                        "&:hover": {
                            cursor: "pointer",
                        },}} >
                        <Stack direction={'row'} sx={{width: '100%'}} alignItems={'center'} onClick={() => props.setTimestamp(parseInt(e.data.timestamp))}>
                            <Typography sx={{width:'21%'}}>{'Placed Ward'}</Typography>
                            <Typography sx={{width:'15%'}}>{dayjs.duration(e.data.timestamp, 'seconds').format('mm:ss')}</Typography>
                            <Stack sx={{width:'64%'}} direction={'row'} spacing={1}>
                                <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${e.summary.champion}.png`} />
                            </Stack>

                        </Stack>
                    </Paper>
            }
        })}
    </Stack>
}

function FeedbackTickets(props: {player: string, game_id: string, timestamp: number}){
    const [tickets, setTickets] = useState([] as ScrimFeedbackTickets[]);
    const api = useReferentialContext().farsightApi.scrimFeedbackTickets;
    const user = useReferentialContext().user;

    function updateTickets(){
        setTickets([]);
        api.list({ordering: ['timestamp'], additional_filters:{players__contains:props.player}}).then(r => setTickets(r))
    }

    useEffect(() => {
        updateTickets();
    }, [props.player]);

    function CreateNote() {
        api.create({id: '1', scrim_feedback_tickets:{
                timestamp: dayjs.duration(props.timestamp, 'seconds').format('mm:ss'),
                players: [props.player].toString(),
                tags: "Gameplay",
                content: "Tell your review",
                game: props.game_id,
                team: user.team
            }}, ).then(r => updateTickets())
    }

    return (
        <Stack
            direction={"column"}
            sx={{
                width: "100%",
                height: "100%",
                border: "2px solid white",
                borderRadius: 2,
                justifyContent: "flex-start",
                alignItems: "center",
                padding: "5px",
                overflow:'auto',
                ...scrollable
            }}
            spacing={1}
        >
            <Grow in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 500 }}>
                <Paper
                    sx={{
                        width: "90%",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        display: "flex",
                    }}
                >
                    {`Notes on ${props.player}`}
                    <Button onClick={CreateNote}>Add Note</Button>
                </Paper>
            </Grow>
            {tickets.map((ticket: any, index: number) => {
                return <FeedbackTicket ticket={ticket} index={index} updateTickets={updateTickets}/>;
            })}
        </Stack>
    );

}

function FeedbackTicket(props: { ticket: ScrimFeedbackTickets; index: number, updateTickets: any}) {
    const { ticket } = props;
    const [editBox, setEditBox] = useState(false);
    const [noteContent, setNoteContent] = useState(props.ticket.content);
    const user = useReferentialContext().user;

    const api = useReferentialContext().farsightApi.scrimFeedbackTickets;

    return (
        <Grow in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: (props.index + 1) * 500 }}>
            <Paper sx={{ width: "90%", borderRadius: 2, padding: "5px" }}>
                <EditNote
                    ticket={ticket}
                    close={() => {
                        setEditBox(false);
                        props.updateTickets()
                    }}
                    open={editBox}
                    setNoteContent={(new_content: string) => {
                        setNoteContent(new_content);
                    }}
                />
                <Stack
                    direction={"row"}
                    sx={{
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography>{ticket.timestamp}</Typography>
                    <Stack direction={'row'}>
                        {ticket.tags?.split(',').map(t => <Chip label={t}/>)}
                    </Stack>
                    <Button
                        onClick={() => {
                            setEditBox(!editBox);
                        }}
                    >
                        <EditIcon />
                    </Button>
                    <Button onClick={() => api.delete({id: ticket.id.toString()}).then(r => props.updateTickets())}>
                        <DeleteIcon/>
                    </Button>
                </Stack>
                <Typography sx={{ width: "100%" }}>{noteContent}</Typography>
            </Paper>
        </Grow>
    );
}

function EditNote(props: { open: boolean; ticket: ScrimFeedbackTickets; close: any; setNoteContent: any }) {
    const [content, setContent] = useState(props.ticket.content as string);
    const [tags, setTags] = useState(props.ticket.tags?.split(','))
    const api = useReferentialContext().farsightApi.scrimFeedbackTickets;

    function handleClose() {
        api.patch({id: props.ticket.id.toString(), patched_scrim_feedback_tickets:{
                content: content
            }}).then(r => props.close())
        return true;
    }


    return (
        <Dialog open={props.open} onClose={handleClose}>
            <DialogTitle>Edit ticket</DialogTitle>
            <DialogContent sx={{ height: "500px" }}>
                <Box sx={{ padding: "20px", width: "500px" }}>
                    <Select multiple value={tags as any} onChange={(event) => setTags(event.target.value as any)} sx={{mb:1, width:'300px'}}
                            label="Tags"
                            renderValue={(value: string) => (
                                <Stack direction={"row"} sx={{ justifyContent: "flex-start", width: "100%" }} spacing={2}>
                                    {value}
                                </Stack>
                            )}>
                        <MenuItem value={'Gameplay'}>Gameplay</MenuItem>
                        <MenuItem value={'Micro'}>Micro</MenuItem>
                        <MenuItem value={'Macro'}>Macro</MenuItem>
                        <MenuItem value={'Recall Timer'}>Recall Timer</MenuItem>
                        <MenuItem value={'Spacing'}>Spacing</MenuItem>
                        <MenuItem value={'Trading'}>Trading</MenuItem>
                        <MenuItem value={'Critical Mistake'}>Critical Mistake</MenuItem>
                        <MenuItem value={'Nashor Setup'}>Critical Mistake</MenuItem>
                        <MenuItem value={'Drake Setup'}>Critical Mistake</MenuItem>
                        <MenuItem value={'Herald Setup'}>Critical Mistake</MenuItem>
                    </Select>
                    <TextField label="Note" variant="filled" fullWidth multiline rows={14} value={content} onChange={(event: any) => setContent(event.target.value)} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.close}>Cancel</Button>
                <Button onClick={handleClose}>Edit Note</Button>
            </DialogActions>
        </Dialog>
    );
}

function ComputeMapState(events: any[], timestamp: number){
    let wards_placed_before = [];
    const f_e = events.filter(e => {
        return (e.data.timestamp < timestamp) && (e.type === 'WARD_EVENT') && (e.data.timestamp > timestamp - 90 || e.data.ward_type === 'control')
    });
    for(const i in f_e){
        console.log(f_e[i]);
        if(f_e[i].data.type === 'PLACED_WARD'){
            f_e[i].data.side = f_e[i].summary.side
            f_e[i].data.player = f_e[i].summary.player
            wards_placed_before.push(f_e[i].data);
        }
        if(f_e[i].data.type === 'KILLED_WARD'){
            for(const y in wards_placed_before){
                if(wards_placed_before[y].position[0] === f_e[i].data.position[0] && wards_placed_before[y].position[1] === f_e[i].data.position[1] && f_e[i].data.ward_type === wards_placed_before[y].ward_type){
                    console.log('Removing ward')
                    console.log(wards_placed_before[y])
                    wards_placed_before.slice(parseInt(y));
                    break;
                }
            }
        }
    }
    const to_ret = {
        wards: wards_placed_before,
        timestamp: timestamp
    }
    console.log(to_ret)

    return to_ret
}