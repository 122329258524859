import dayjs from "dayjs";
import React, { useState } from "react";
import {Grid, Stack} from "@mui/material";
import FilterSet from "../../../components/selectors/Filterset";
import { GamesDataApi, GamesData, League } from "../../../farsight-api";
import {targets} from "../../../Context";
import TableDisplayGrid, { rowStyle } from "../../../components/datagrids/shared/TableDisplayGrid";
import { ColumnTypes } from "../../../components/datagrids/styled_datagrid";
import { useReferentialContext } from "../../../Context";
import WindowContainer from "../../../components/layout/WindowContainer";
import Button from "@mui/material/Button";

export default function LeagueMatchHistory() {
    const [from, setFrom] = useState(dayjs().subtract(3, "month"));
    const [until, setUntil] = useState(dayjs());
    const [leagues, setLeagues] = useState([] as League[]);

    const [paramObject, setParamObject] = useState({
        ordering: "-date",
    } as any);
    console.log(paramObject);
    const ctx = useReferentialContext();
    const api = ctx.farsightApi.gamesData;

    const columnGroupingModel= [
        {
            groupId: "Drafting Sim V2",
            children: [{ field: "drafting_sim_team1_pred" }, { field: "drafting_sim_team2_pred" }, { field: "drafting_sim_pred_accurate" }],
            headerClassName: "header",
        },
        {
            groupId: "Drafting Sim V3",
            children: [{ field: "drafting_sim_v3_team1_pred" }, { field: "drafting_sim_v3_team2_pred" }, { field: "match_id" }],
            headerClassName: "header"
        },
        {
            groupId: "Odds",
            children: [{ field: "pre_game_odds_team1" }, { field: "pre_game_odds_team2" }, { field: "pre_game_pred_accurate" }],
            headerClassName: "header"
        }
    ]

    return (
        <WindowContainer direction={"column"} spacing={2} sx={{height:'100%'}}>
            <Stack direction={'row'}>
                <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={targets.competitive} leagues={leagues} setLeagues={setLeagues} date__gte={from} setDateGte={setFrom} date__lt={until} setDateLt={setUntil} />
                <Button variant={'outlined'} onClick={() => api.list(
                    paramObject
                ).then(r => r.map(g => ctx.farsightApi.download_file('Downloads/GameReplay',
            {game_id:g.game_id, target: targets.competitive},
            `${g.league} - ${dayjs(g.date).format('MMM DD')} - ${g.team1} vs ${g.team2} - Bo${g.best_of} game ${g.game_n ? g.game_n : g.game_id.charAt(g.game_id.length - 1)}.rofl`)))}>Download All Replays</Button>
            </Stack>
            {leagues.length > 0 && paramObject.additional_filters.league__in ? <TableDisplayGrid
                display_all={false}
                request={() => api.list(paramObject)}
                rowStyle={rowStyle.outcome}
                sortBy={"date"}
                paramObject={paramObject}
                disp_columns={["league", "date", "team1", "team2", "patch", "duration", "winner", "team1champions", "team2champions", "game_id", "vodGameStart",
                    "drafting_sim_team1_pred", "drafting_sim_team2_pred", "drafting_sim_pred_accurate", "drafting_sim_v3_team1_pred",
                    "drafting_sim_v3_team2_pred", "drafting_sim_v3_pred_accurate", "pre_game_odds_team1", "pre_game_odds_team2", 'pre_game_pred_accurate'
                ]}
                grouping_model={columnGroupingModel}
            /> : <></>}
        </WindowContainer>
    );
}
