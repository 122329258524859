import {
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fade,
    Grid,
    Grow, Icon,
    IconButton, Link,
    Paper,
    Stack, Tooltip,
    Typography
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel, {a11yProps} from "../../../components/tabs_switch/tabs_panel";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import PlayerPresentation from "../../../components/visu_blocks/PlayerPresentation";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayerEvolutionPerPatch from "../../../components/Charts/PlayerEvolutionPerPatch";
import AccountsSelect from "../../../components/selectors/SoloQ/AccountSelect";
import WindowContainer from "../../../components/layout/WindowContainer";
import FilterSet from "../../../components/selectors/Filterset";
import {soloq_servers, targets, useReferentialContext} from "../../../Context";
import {OffseasonNotes, Player, SoloQAccounts} from "../../../farsight-api";
import PlayerPerformanceProfile from "../../../components/Charts/PlayerPerformanceProfile";
import PerformanceScoresRadar, {LanePerformanceScoresRadar} from "../../Shared/ScoreRadar";
import CompetitivePlayerEvolution from "../../../components/Charts/CompetitivePlayerEvolution";
import SoloQPlayerEvolution from "../../../components/Charts/PlayerEvolutionPerPatch";
import DeeplolIcon from "../../../components/Icons/DeeplolIcon";
import OpggIcon from "../../../components/Icons/OpggIcon";
import PerformancePerElo from "../../../components/Charts/PerformancePerElo";
import DpmIcon from "../../../components/Icons/DpmIcon";

export default function OffseasonPlayerAnalysis() {
    const context = useReferentialContext();

    const [tab, setTab] = useState(0);
    const [accounts, setAccounts] = useState([] as SoloQAccounts[]);
    const [paramObject, setParamObject] = useState({} as any);
    const [player, setPlayer] = useState(context.selectedPlayer);
    const [year, setYear] = useState(2024);
    const [phase, setPhase] = useState([]);
    const [split, setSplit] = useState(null);
    const [leagues, setLeagues] = useState(context.leaguesReferential.filter(l => l.league_name === 'All Leagues'));

    return (
        <WindowContainer spacing={2} >
            <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={targets.competitive}
                       player={player} setPlayer={setPlayer}
                       year={year} setYear={setYear}
                       phases={phase} setPhases={setPhase}
                       split={split} setSplit={setSplit}
                       leagues={leagues} setLeagues={setLeagues}
            />
                <Grow in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 1000 }}>
                    <Tabs value={tab} onChange={(event, newvalue, ) => setTab(newvalue)}>
                        <Tab label="Resume" {...a11yProps(0)} />
                        <Tab label="Competitive Scores" {...a11yProps(1)} />
                        <Tab label="Competitive" {...a11yProps(2)} />
                        <Tab label="SoloQ Evolution" {...a11yProps(3)} />
                        <Tab label="SoloQ Distribution" {...a11yProps(4)} />
                        {/*<Tab label="Edit Informations" {...a11yProps(4)} />*/}
                    </Tabs>
                </Grow>
                <TabPanel value={tab} index={0}>
                    {player ? <PlayerOffseasonOverview paramObject={paramObject} player={player} year={year}/> : <></>}
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <Stack direction={'row'} sx={{height:'90%', width:'100%'}}>
                        <Box sx={{width:'50%', height:'100%'}}><PerformanceScoresRadar target={targets.competitive} paramObject={paramObject}/></Box>
                        {year >= 2023 ? <Box sx={{width: '50%', height: '100%'}}><LanePerformanceScoresRadar
                            target={targets.competitive} paramObject={paramObject}/></Box> : <></>}
                    </Stack>
                </TabPanel>
                <TabPanel value={tab} index={2}>
                    {player ? <CompetitivePlayerEvolution player={player} year={year}/> : <></>}
                </TabPanel>
                <TabPanel value={tab} index={3}>
                    {
                        player ? <Stack direction={'column'} sx={{height:'100%'}}>
                            <AccountsSelect player={player} value={accounts} onChange={(newValue: SoloQAccounts[]) => setAccounts(newValue)} />
                            <SoloQPlayerEvolution year={year} accounts={accounts}/>
                        </Stack> : <></>
                    }
                </TabPanel>
                <TabPanel value={tab} index={4}>
                    {player ? <Stack direction={'column'} sx={{height:'100%'}}>
                            <AccountsSelect player={player} value={accounts} onChange={(newValue: SoloQAccounts[]) => setAccounts(newValue)} />
                            <PerformancePerElo year={year} accounts={accounts}/>
                        </Stack>: <></>}
                </TabPanel>
        </WindowContainer>
    );
}

export function PlayerOffseasonOverview(props: {paramObject: any, player: Player, year: number}) {
    const context = useReferentialContext();
    const [paramObject, setParamObject] = useState({
                additional_filters: {
                    date__year: props.year,
                    patch__in:['14.14,14.15,14.13']
                },
                role: props.player.role
            } as any);


    useEffect(() => {
        setParamObject({
                additional_filters: {
                    date__year: props.year,
                    patch__in:['14.14,14.15,14.13']
                },
                role: props.player.role
            })


    }, [props.year]);

    return (
        <Stack
            direction={"row"}
            sx={{
                width: "100%",
                height: "93%",
                display: "flex",
                justifyContent: "space-around",
            }}
        >
            {props.player ? <PlayerPresentation player={props.player} /> : <></>}
            <Stack sx={{width:'45%', align:'center',justify:'center',display:'flex'}} direction={'column'}>
                {/*<Typography variant="h4" sx={{textAlign:'center'}}>{'Competitive'}</Typography>*/}
                <PlayerPerformanceProfile target={targets.competitive} paramObject={props.paramObject} player={props.player}/>
            </Stack>
            <Stack sx={{width:'45%', align:'center',justify:'center',display:'flex'}} direction={'column'}>
                <Typography variant="h4" sx={{textAlign:'center'}}>{'SoloQ'}</Typography>
                <SoloQAccountsCards player={props.player} year={props.year}/>
            </Stack>
        </Stack>
    );
}


export function SoloQAccountsCards(props:{player: Player, year: number}){
    const context = useReferentialContext();
    const [accounts, setAccounts] = useState([] as SoloQAccounts[]);

    useEffect(() => {
        setAccounts([]);
        context.farsightApi.soloqAccount.list({ordering:['-elo_as_total_lps'],
            player: props.player.player, additional_filters: {most_recent_game__year__gte:props.year}}).then(
            r => setAccounts(r)
        )
    }, [props.player, props.year]);

    return <Stack direction={'row'} sx={{flexWrap: "wrap", flexDirection: "row"}} justifyContent={"space-evenly"}>
        {accounts.map(acc => <AccountCard account={acc} year={props.year}/>)}
    </Stack>
}

function AccountCard(props: {account: SoloQAccounts, year: number}){
    const context = useReferentialContext();
    const [accStats, setAccStats] = useState(undefined as undefined | any);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setAccStats(undefined);
        setLoading(true);

        context.farsightApi.getSoloQApiByServer(props.account.server ? props.account.server as any : soloq_servers.ALL).aggregate({account: props.account.id, additional_filters:{date__year:props.year}, groupby:['account'],
        metrics:['performance,relative_performance,games,winrate,elo,kda,gd15,csd15,include_in_performance'], ordering:['account']}).then(r => {
            setLoading(false);
            setAccStats(r[0]);
        })
    }, [props.account, props.year]);


    return <Grow in={true} style={{ transformOrigin: "0 0 0" }} {...{timeout: Math.floor(Math.random() * (4000 - 2000 + 1) + 2000),}}>
        <Card sx={{mt:1, width:'350px'}}>
            <CardHeader
            action={
              <Stack direction={'row'} spacing={1} sx={{
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        height:'100%',
                    }}>
                    <DeeplolIcon account={props.account}/>
                    <OpggIcon account={props.account}/>
                    <DpmIcon account={props.account}/>
              </Stack>
            }
            title={props.account.account_name}
            subheader={`${props.account.server} - ${props.account.elo ? props.account.elo: 'Unranked'}`}
          />
            <CardContent>
                {accStats && accStats.games > 1? <Stack direction={'column'}>
                        <Typography>{`${accStats.games} games in ${props.year} (${accStats.include_in_performance} rated)`}</Typography>
                        <Typography>{`${(accStats.winrate*100).toFixed(1)}% winrate`}</Typography>
                        {accStats.performance ? <Typography>{`${accStats.performance.toFixed(1)} P (${accStats.relative_performance.toFixed(1)} RP)`}</Typography> : <Typography>No rated games</Typography>}
                        <Typography>{`${(accStats.elo - 3100).toFixed(0)} LP on average`}</Typography>
                        <Typography>{`${(accStats.kda).toFixed(1)} KDA`}</Typography>
                        <Typography>{`${(accStats.gd15).toFixed(1)} GD@15 / ${(accStats.csd15).toFixed(1)} CSD@15`}</Typography>
                    </Stack>
                    : (loading ? <CircularProgress/> : <Typography>No games</Typography>)}
          </CardContent>
        </Card>
    </Grow>
}





function PlayerNote(props: { note: OffseasonNotes; index: number; deleteNote: any }) {
    const { note } = props;
    const [editBox, setEditBox] = useState(false);
    const [noteContent, setNoteContent] = useState(props.note.content);
    const user = useReferentialContext().user;

    return (
        <Grow in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: (props.index + 1) * 500 }}>
            <Paper sx={{ width: "90%", borderRadius: 2, padding: "5px" }}>
                <EditNote
                    note={note}
                    close={() => setEditBox(false)}
                    open={editBox}
                    setNoteContent={(new_content: string) => {
                        setNoteContent(new_content);
                    }}
                />
                <Stack
                    direction={"row"}
                    sx={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent:'space-between'
                    }}
                >
                    <Stack direction={'row'} sx={{width:'100%', justifyContent:'space-evenly'}}>
                        <Typography>{note.author}</Typography>
                        {/*<Typography>{note.author_organization}</Typography>*/}
                    </Stack>
                    <Stack direction={'row'} sx={{justifyContent: 'right', display:'flex', alignItems:'right'}}>
                        <Button
                            onClick={() => {
                                setEditBox(!editBox);
                            }}
                        >
                            <EditIcon />
                        </Button>
                        <Button disabled={user.username !== note.author} onClick={() => props.deleteNote(props.note)}>
                            <DeleteIcon />
                        </Button>
                    </Stack>
                </Stack>
                <Typography sx={{ width: "100%" }}>{noteContent}</Typography>
            </Paper>
        </Grow>
    );
}

function EditNote(props: { open: boolean; note: any; close: any; setNoteContent: any }) {
    const [content, setContent] = useState(props.note.content as string);
    const api = useReferentialContext().farsightApi.offSeasonNotes;

    function handleClose() {
        api.patch({
            id: props.note.id,
            patched_offseason_notes: {
                content: content
            }
        })
        props.setNoteContent(content);
        props.close();
        return true;
    }

    // @ts-ignore
    return (
        <Dialog open={props.open} onClose={handleClose}>
            <DialogTitle>Edit Note</DialogTitle>
            <DialogContent sx={{ height: "500px" }}>
                <Box sx={{ padding: "20px", width: "500px" }}>
                    <TextField label="Note" variant="filled" fullWidth multiline rows={15} value={content} onChange={(event: any) => setContent(event.target.value)} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.close}>Cancel</Button>
                <Button onClick={handleClose}>Edit Note</Button>
            </DialogActions>
        </Dialog>
    );
}
