import {targets, useReferentialContext} from "../../../Context";
import WindowContainer from "../../../components/layout/WindowContainer";
import React, {useEffect, useState} from "react";
import FilterSet from "../../../components/selectors/Filterset";
import {CompetitiveParticipantTeamfight, CompetitiveTeamfight, GamesData, Team} from "../../../farsight-api";
import dayjs, {Dayjs} from "dayjs";
import SpecificGameSelector from "../../../components/selectors/Competitive/SpecificGameSelector";
import {Checkbox, Divider, Grid, Stack, Typography} from "@mui/material";
import {PositionMapDraws} from "./PositionHeatmap";
import TeamfightSelect from "../../../components/selectors/Competitive/TeamfightSelect";
import ChampionIcon from "../../../components/Icons/ChampionIcon";
import ItemIcon from "../../../components/Icons/ItemIcon";
import Box from "@mui/material/Box";
import {float} from "html2canvas/dist/types/css/property-descriptors/float";
import {TimestampSelect} from "../../../components/selectors/TimestampRangeSelect";
import MapDrawer from "../../../components/maps/map_drawer";


export default function CheckTeamfights(props: {target: targets}) {
    const [selectedGame, setSelectedGame] = useState(null as GamesData|null);

    const [team, setTeam] = useState<Team | null>(useReferentialContext().selectedTeam);
    const [from, setFrom] = useState<Dayjs>(dayjs().subtract(3, "month"));
    const [until, setUntil] = useState<Dayjs>(dayjs());
    const [ingameInterval, setIngameInterval] = useState([0,600]);
    const [paramObject, setParamObject] = useState({
        ordering: "-date",
    } as any);
    const [teamfight, setTeamfight] = useState<CompetitiveTeamfight|null>(null);

    const updateSelection = (gameSelected: any[]) => {
        setSelectedGame(gameSelected[0]);
    };

    return <WindowContainer direction={'column'} spacing={2}>
        <FilterSet
                paramObject={paramObject}
                setParamObject={setParamObject}
                date__gte={from}
                setDateGte={setFrom}
                date__lt={until}
                setDateLt={setUntil}
                team={team}
                setTeam={setTeam}
                ingameInterval={ingameInterval}
                setIngameInterval={setIngameInterval}
                target={props.target}
            />
        <Stack
                direction={"row"}
                sx={{
                    height: "85%",
                    width: "100%",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "flex-start",
                }}
                spacing={1}
            >
                {paramObject.team && team ?
                    <SpecificGameSelector team={team} updateSelection={updateSelection} from={from} until={until} include_bayes={true} side_select={true} target={props.target} single={true} />
                    : <></>
                }
                {selectedGame ? <TeamfightSelect target={props.target} paramObject={{game:selectedGame.game_id}} perspective={team} updateSelection={(r: any) => setTeamfight(r[0])}/> : <></>}
                {teamfight ? <TeamfightViewer teamfight={teamfight}/> : <></>}
            </Stack>
    </WindowContainer>
}

function TeamfightViewer(props: {teamfight: CompetitiveTeamfight}) {
    const api = useReferentialContext().farsightApi.competitiveParticipantTeamfights;
    const [summaries, setSummaries] = useState<CompetitiveParticipantTeamfight[]>([]);
    const [timestamp, setTimestamp] = useState(0);

    useEffect(() => {
        console.log(props.teamfight)
        api.list({teamfight: props.teamfight.id, ordering:['id']}).then(r => setSummaries(r));
    }, [props.teamfight]);

    return <Stack direction={'column'} sx={{height:'100%'}}>
        {/*{props.teamfight.id}*/}
        <Stack direction={'row'} spacing={1} sx={{height:'35%'}}>
            <Stack direction={'column'}>
                <TeamHeader direction={'row'}/>
                {
                    summaries.slice(0,5).map(s => <PlayerRowState summary={s} direction={'row'}/>)
                }
            </Stack>
            <Stack direction={'column'}>
                <TeamHeader  direction={'row-reverse'}/>
                {
                    summaries.slice(5,10).map(s => <PlayerRowState summary={s} direction={'row-reverse'}/>)
                }
            </Stack>
        </Stack>

        <Stack direction={'column'} sx={{justifyContent:'center', display:'flex', alignItems:'center', height:'65%'}} spacing={1}>
            <TimestampSelect max={props.teamfight.ended_at - props.teamfight.started_at - 1} value={timestamp} onChange={setTimestamp}/>
            <Grid container sx={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', height:'100%'}}>
            <MapDrawer points={[]} positions={ summaries[0] ? summaries.map(ps => {
                return {
                    player: ps.player,
                    champion : ps.champion,
                    position : ps.position_history[timestamp]
                }
            }) : []}/></Grid>
        </Stack>
    </Stack>
}

function TeamHeader(props: {direction: "row" | "row-reverse"}){
    return <Stack direction={props.direction} sx={{
                            alignItems: "center",
                            display: "flex",
                            backgroundColor: '#181818',
                            color:'white',
                            width:'100%',
                            height:'40px'
                        }}>
                            <Typography sx={{width:'300px', alignItems:'center', justifyContent:'center', display:'flex'}}>{'Items'}</Typography>
                            <Typography sx={{width:'30px', alignItems:'center', justifyContent:'center', display:'flex'}}>{'LVL'}</Typography>
                            <Typography sx={{width:'30px', alignItems:'center', justifyContent:'center', display:'flex'}}>{'F.'}</Typography>
                            <Typography sx={{width:'70px', alignItems:'center', justifyContent:'center', display:'flex'}}>{'Player'}</Typography>
                            <Typography sx={{width:'40px', alignItems:'center', justifyContent:'center', display:'flex'}}>{`C.`}</Typography>
                        </Stack>
}

function PlayerRowState(props: {summary: CompetitiveParticipantTeamfight, direction: 'row'|'row-reverse'}){
    const s = props.summary;
    return <Stack direction={props.direction} sx={{width:'100%'}}>
                <Stack direction={'row'} sx={{width:'300px', overflow:'wrap', display:'flex', alignItems:'center', justifyContent:'center'}}>
                    {
                        s.initial_state?.items.map((i: string) => <ItemIcon item={i}/>)
                    }
                </Stack>
                <Typography sx={{width:'30px', alignItems:'center', justifyContent:'center', display:'flex'}}>{s.initial_state?.level.toString()}</Typography>
                {/*<Typography>{s.flash_up?.toString()}</Typography>*/}
                <Checkbox checked={s.flash_up === true} sx={{width:'30px'}}/>
                <Typography sx={{width:'70px', alignItems:'center', justifyContent:'center', display:'flex'}}>{s.player.split(' (')[0]}</Typography>
                <ChampionIcon champion={s.champion} role={s.role}/>
            </Stack>
}