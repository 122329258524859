


export class Formatters {
    percent: (v: number) => string;
    integer: (v: number) => string;
    delta: (v: number) => string;

    constructor() {
        this.percent = (v: number) => `${(v*100).toFixed(1)}%`
        this.integer = (v: number) => `${v}`
        this.delta = (v: number) => v < 0 ? `${v.toFixed(1)}` : `+${v.toFixed(1)}`
    }
}

const formatters = new Formatters();
export default formatters;