import React, {useEffect, useState} from "react";
import {Grow, Stack, Typography} from "@mui/material";
// import ChampionSelect from "../../../../components/selectors/Shared/ChampionSelect";
import {Champion, targets, useReferentialContext} from "../../../../Context";
import WindowContainer from "../../../../components/layout/WindowContainer";
import FilterSet from "../../../../components/selectors/Filterset";
import StyledDatagrid, {ColumnTypes} from "../../../../components/datagrids/styled_datagrid";
import {SingleChampionSelect} from "../../../../components/selectors/Shared/ChampionSelect";
import TableDisplayGrid, {rowStyle} from "../../../../components/datagrids/shared/TableDisplayGrid";

export default function BotlaneMatchupsCompetitive() {
    const context = useReferentialContext();
    // const [state, setState] = useState({
    //     patch: current_patch.toString(),
    //     leagues: current_leagues.map((league: any) => league.league_name),
    //     bottom: "Jinx",
    //     support: "Thresh",
    // });

    const [paramObject, setParamObject] = useState({} as any);
    const [patches, setPatches] = useState(context.selectedPatches);
    const [leagues, setLeagues] = useState(context.selectedLeagues);
    const [adc, setAdc] = useState(null as Champion|null);
    const [support, setSupport] = useState(null as Champion|null);

    // useEffect(() => {
    //     context.farsightApi.computes.botlane_matchups({
    //
    //     })
    // }, []);


    return (
        <WindowContainer spacing={2}>
            <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={targets.competitive} leagues={leagues} setLeagues={setLeagues} patches={patches} setPatches={setPatches}/>
            <Stack direction={'row'} spacing={2}>
                <SingleChampionSelect value={adc} updateValue={setAdc} label={'ADC'}/>
                <SingleChampionSelect value={support} updateValue={setSupport} label={'Support'}/>
            </Stack>
            {adc && support ?
                <Grow in={true} style={{ transformOrigin: "0 -50 0" }} {...{ timeout: 2000 }}>
                <Stack direction="row" sx={{ height: "90%", width: "100%" }}>
                    <BotlaneMatchups target={targets.competitive} bottom={adc} support={support} paramObject={paramObject}/>
                </Stack>
            </Grow> : <Typography>Select ADC and support</Typography>
            }

</WindowContainer>
    );
}

function BotlaneMatchups(props: {paramObject: any, target: targets, bottom: Champion, support: Champion}){
    const ctx = useReferentialContext();
    const api = ctx.farsightApi.competitiveGameSummaries;
    const request_parameters = {
                            ...props.paramObject, bottom: props.bottom.id, support: props.support.id,
                            metrics: ['performance,relative_performance,gd10,games,winrate']
                        };

    let columnGroupingModel = [
        {
            groupId: props.bottom.name,
            children: [{ field: "ad" }].concat(
                ['performance', 'relative_performance', 'gd10'].map(m => ({
                    field: `ad_${m}`
                }))
            ),
            headerClassName: "header",
        },
        {
            groupId: props.support.name,
            children: [{ field: "support" }].concat(
                ['performance', 'relative_performance', 'gd10'].map(m => ({
                    field: `support_${m}`
                }))
            ),
            headerClassName: "header",
        },
        {
            groupId: 'Matchup AD',
            children: [{ field: "matchup_ad" }].concat(
                ['performance', 'relative_performance', 'gd10'].map(m => ({
                    field: `matchup_ad_${m}`
                }))
            ),
            headerClassName: "header",
        },
        {
            groupId: 'Matchup Support',
            children: [{ field: "matchup_support" }].concat(
                ['performance', 'relative_performance', 'gd10'].map(m => ({
                    field: `matchup_support_${m}`
                }))
            ),
            headerClassName: "header",
        },
    ];

    return <TableDisplayGrid
                        grouping_model={columnGroupingModel}
                        display_all={false}
                        request={() => api.botlane_matchups(request_parameters)}
                        rowStyle={rowStyle.none}
                        sortBy={"ad_games"}
                        paramObject={request_parameters}
                        custom_columns={[
                            ColumnTypes.champion("ad", "AD."),
                            ColumnTypes.rating("ad_performance", "AD P."),
                            ColumnTypes.rating("ad_relative_performance", "AD RP."),
                            ColumnTypes.rating("ad_gd10", "AD GD10"),
                            ColumnTypes.champion( "support", "Sup"),
                            ColumnTypes.rating("support_performance", "Sup P"),
                            ColumnTypes.rating("support_relative_performance", "Sup RP"),
                            ColumnTypes.rating("support_gd10", "Sup GD10"),
                            ColumnTypes.champion("matchup_ad", "AD."),
                            ColumnTypes.rating("matchup_ad_performance", "MU AD P."),
                            ColumnTypes.rating("matchup_ad_relative_performance", "MU AD RP."),
                            ColumnTypes.rating("matchup_ad_gd10", "MU AD GD10"),
                            ColumnTypes.champion("matchup_support", "Sup"),
                            ColumnTypes.rating("matchup_support_performance", "MU Sup P"),
                            ColumnTypes.rating("matchup_support_relative_performance", "MU Sup RP"),
                            ColumnTypes.rating("matchup_support_gd10", "Sup GD10"),
                            ColumnTypes.short_int("ad_games", "#"),
                            ColumnTypes.percentage("ad_winrate", `${props.bottom.id}/${props.support.id} WR`),
                        ]}
                    />


}
