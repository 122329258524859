import {targets, useReferentialContext} from "../../../Context";
import TableDisplayGrid, {rowStyle} from "./TableDisplayGrid";
import React, {useState} from "react";


export default function GamesHistory(props: {paramObject: any, target: targets, hide_columns?: string[]}){
    const api = useReferentialContext().farsightApi.getSummariesApi(props.target);

    const target_to_columns: { [target: string]: string[] } = {
        competitive: ['player','team1','team2','vod','replay','expected_winrate'],
        soloq: ['account_name','competitive_player'],
        scrims: ['player','team1','team2' ],
        championsq: ['player']
    }

    let disp_columns = [...target_to_columns[props.target],  ...['patch','date', 'champion','direct_matchup', 'boots','item1', 'item2', 'item3','item4','item5', 'keystone', 'kda']]
    if(props.hide_columns !== undefined){
        for(const i in props.hide_columns){
            const el_index = disp_columns.indexOf(props.hide_columns[i]);
            if(el_index != -1){
                disp_columns.splice(el_index, 1)
            }

        }
    }
    const [metrics, setMetrics] = useState(
        useReferentialContext().metricsReferential.filter(metric => metric.available_for?.includes(props.target) && ["performance", "relative_performance", "win", "gd15", "csd15", "cspm",'performance_lane','relative_performance_lane'].includes(metric.code)))

    console.log(metrics)

    return <TableDisplayGrid
            display_all={false}
            request={() => api.list(props.paramObject)}
            rowStyle={rowStyle.win}
            disp_columns={disp_columns}
            sortBy={"date"}
            paramObject={props.paramObject}
            metrics={metrics}
        />
}