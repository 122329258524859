import {targets, useReferentialContext} from "../../Context";
import {Fade, Grow, Link, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FarsightApi from "../../ApiWrapper";
import React from "react";
import TeamIcon from "../Icons/TeamIcon";


export default function Level1Map(props: { team: string; match: any; index: number, target: targets, sx?: any }) {
    const api = useReferentialContext().farsightApi;
    return (
            <Box
                sx={{
                    // width: "512px",
                    height: "670px",
                    padding: "10px",
                    border:'2px solid white',
                    borderRadius: 2,
                    ...props.sx
                }}
            >
                {/*{props.match.game_id}*/}
                <Stack
                    direction={"row"}
                    sx={{
                        alignItems: "space-evenly",
                        justifyContent: "space-evenly",
                    }}
                >
                        <TeamIcon team_name={props.match.team1}/>
                        <TeamIcon team_name={props.match.team2}/>
                </Stack>
                    <Stack direction={"row"} sx={{ alignItems: "center", justifyContent: "center" }}>
                        <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${props.match.champion_TOP_LANE}.png`} />
                        <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${props.match.champion_JUNGLE}.png`} />
                        <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${props.match.champion_MID_LANE}.png`} />
                        <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${props.match.champion_BOT_LANE}.png`} />
                        <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${props.match.champion_UTILITY}.png`} />

                        <Typography align={"center"} justifyContent={"flex-start"}>
                            <pre style={{ fontFamily: "inherit" }}>{"vs"}</pre>
                        </Typography>
                        <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${props.match.matchup_TOP_LANE}.png`} />
                        <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${props.match.matchup_JUNGLE}.png`} />
                        <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${props.match.matchup_MID_LANE}.png`} />
                        <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${props.match.matchup_BOT_LANE}.png`} />
                        <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${props.match.matchup_UTILITY}.png`} />
                    </Stack>

                    <Box
                        component={"img"}
                        src={`${api.url}/Maps/Level1?id=${props.match.game_id}&target=${props.target}&team=${props.team}`}
                        sx={{
                            width:'400px',
                            height:'400px',
                            borderRadius: 2,
                        }}
                        style={{ opacity: 0.7 }}
                    />

                    <Stack
                        direction={"row"}
                        sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                        }}
                        spacing={1}
                    >
                        <Stack
                            direction={"row"}
                            sx={{
                                alignItems: "center",
                                justifyContent: "left",
                                width: "100%",
                            }}
                            spacing={1}
                        >
                            <Typography>{`${props.match.patch} - ${props.match.side} side`}</Typography>
                        </Stack>
                        {props.match.vodGameStart ? (
                            <Link href={props.match.vodGameStart} target="_blank">
                                <Button variant={"outlined"}>VOD</Button>
                            </Link>
                        ) : (
                            <></>
                        )}
                        {props.match.replayFile ? (
                            <Button
                                variant={"outlined"}
                                onClick={() =>
                                    FarsightApi.download(
                                        "GameReplay",
                                        {
                                            id: props.match.game_id,
                                            target: "competitive",
                                        },
                                        `${props.match.game_id} - ${props.match.team1_id} vs ${props.match.team2_id}`,
                                    )
                                }
                            >
                                Replay
                            </Button>
                        ) : (
                            <></>
                        )}
                    </Stack>
            </Box>
    );
}