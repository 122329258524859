import Box from "@mui/material/Box";
import global_context, { api_url } from "../../static_vars";
import { Fade, Grow, Link, Stack, Typography } from "@mui/material";
import React, {useContext, useRef} from "react";
import FarsightApi from "../../ApiWrapper";
import Button from "@mui/material/Button";
import {targets, useReferentialContext} from "../../Context";
import html2canvas from "html2canvas";
import TeamIcon from "../Icons/TeamIcon";

export default function JunglePathingMap(props: { team: string; match: any; index: number, target: targets, sx?: any }) {
    const api = useReferentialContext().farsightApi;
    const gridRef = useRef(null);

    const handleCapture = () => {
      if (gridRef.current) {
          // Ensure that the DataGrid is fully rendered before capturing
          // await new Promise((resolve) => setTimeout(resolve, 100)); // Adjust delay if necessary


          // @ts-ignore
          html2canvas(gridRef.current, {
              logging: true,
              useCORS: true,
              allowTaint: true,
              backgroundColor: '#000000',
          }).then((canvas) => {
              canvas.toBlob(async (blob) => {
              try {
                await navigator.clipboard.write([
                    // @ts-ignore
                  new ClipboardItem({ 'image/png': blob })
                ]);
                // alert('Image copied to clipboard!');
              } catch (err) {
                console.error('Failed to copy image to clipboard: ', err);
                alert('Failed to copy image to clipboard')
              }
            }, 'image/png');
          })
      }
    }

    return (
            <Box
                ref={gridRef}
                sx={{
                    // width: "512px",
                    height: "670px",
                    padding: "10px",
                    border:'2px solid white',
                    borderRadius: 2,
                    ...props.sx
                }}
            >
                {/*{props.match.game_id}*/}
                <Stack
                    direction={"row"}
                    sx={{
                        alignItems: "space-evenly",
                        justifyContent: "space-evenly",
                        width:'100%'
                    }}
                >
                    <TeamIcon team_name={props.match.team1}/>
                    <TeamIcon team_name={props.match.team2}/>
                </Stack>
                    <Stack direction={"row"} sx={{ alignItems: "center", justifyContent: "center", width:'100%' }}>
                        <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${props.match.champion_TOP_LANE}.png`} />
                        <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${props.match.champion_JUNGLE}.png`} />
                        <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${props.match.champion_MID_LANE}.png`} />
                        <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${props.match.champion_BOT_LANE}.png`} />
                        <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${props.match.champion_UTILITY}.png`} />

                        <Typography align={"center"} justifyContent={"flex-start"}>
                            <pre style={{ fontFamily: "inherit" }}>{"vs"}</pre>
                        </Typography>
                        <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${props.match.matchup_TOP_LANE}.png`} />
                        <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${props.match.matchup_JUNGLE}.png`} />
                        <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${props.match.matchup_MID_LANE}.png`} />
                        <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${props.match.matchup_BOT_LANE}.png`} />
                        <Box component={"img"} src={`https://res.cloudinary.com/xenesis/image/upload/c_scale,h_${40},w_${40}/v1/championAssets/${props.match.matchup_UTILITY}.png`} />
                    </Stack>
                    <Box
                        component={"img"}
                        src={`${api.url}/Maps/FirstPathing?role=JUNGLE&id=${props.match.game_id}&target=${props.target}&team=${props.team}`}
                        sx={{
                            // width: props.sx?.width ? props.sx?.width : "512px",
                            // height: props.sx?.width ? props.sx?.width : "512px",
                            width:'400px',
                            height:'400px',
                            borderRadius: 2,
                        }}
                        style={{ opacity: 0.7 }}
                    />
                    <Stack
                        direction={"row"}
                        sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                        }}
                        spacing={1}
                    >
                        <Stack
                            direction={"row"}
                            sx={{
                                alignItems: "center",
                                justifyContent: "left",
                                width: "100%",
                            }}
                            spacing={1}
                        >
                            <Typography>{`${props.match.patch} - ${props.match.side} side`}</Typography>
                        </Stack>
                        {props.match.vodGameStart ? (
                            <Link href={props.match.vodGameStart} target="_blank">
                                <Button variant={"outlined"}>VOD</Button>
                            </Link>
                        ) : (
                            <></>
                        )}
                        {props.match.replayFile ? (
                            <Button
                                variant={"outlined"}
                                onClick={() =>
                                    FarsightApi.download(
                                        "GameReplay",
                                        {
                                            id: props.match.game_id,
                                            target: "competitive",
                                        },
                                        `${props.match.game_id} - ${props.match.team1_id} vs ${props.match.team2_id}`,
                                    )
                                }
                            >
                                Replay
                            </Button>
                        ) : (
                            <></>
                        )}
                        <Button onClick={() => handleCapture()} variant={'outlined'}>PNG</Button>
                    </Stack>
            </Box>
    );
}
