import {SoloQAccounts} from "../../farsight-api";
import {Link, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";

export default function DpmIcon(props: {account: SoloQAccounts, sx? :any}){
    return <Tooltip title={"DPM"} followCursor={true} sx={{ display: 'inline-flex', alignItems: 'center' }}>
                <Link href={"https://dpm.lol/" + props.account.account_name} target="_blank">
                    <Box
                        component={"img"}
                        src="../icons/dpm.ico"
                        alt="op.gg"
                        sx={props.sx ? props.sx : {
                            width: "25px",
                            height: "25px",
                        }}
                    />
                </Link>
            </Tooltip>

}