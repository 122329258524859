import {targets, useReferentialContext} from "../../Context";
import React, {useEffect, useState} from "react";
import WindowContainer from "../../components/layout/WindowContainer";
import FilterSet from "../../components/selectors/Filterset";
import {Stack} from "@mui/material";
import TableDisplayGrid, {rowStyle} from "../../components/datagrids/shared/TableDisplayGrid";
import {CompetitiveGameSummariesChampionRatingsRequest} from "../../farsight-api";
import {ChampionsQueueSummariesChampionRatingsRequest} from "../../farsight-api/apis/ChampionsQueueSummariesApi";

export default function ChampionsQChampionsRatings() {
    const context = useReferentialContext();
    const [patches, setPatches] = useState(context.selectedPatches as string[]);
    const [paramObject, setParamObject] = useState({} as any);
    const [detailed, setDetailed] = useState(false);

    return (
        <WindowContainer spacing={2}>
            <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={targets.soloq} patches={patches} setPatches={setPatches}/>
            {!detailed ? <OverviewTierlists paramObject={paramObject} ratings_endpoint={context.farsightApi.championsqGameSummaries.champion_ratings}/> :
                <DetailedTierlist paramObject={paramObject} />}
        </WindowContainer>
    );
}

function OverviewTierlists(props: { paramObject: any, ratings_endpoint: any  }) {
    const cols = ["champion", "games", "rating","winrate", "banrate"]
    const context = useReferentialContext();
    const endpoint = context.farsightApi.championsqGameSummaries;
    return props.paramObject.watermark ? (
        <Stack
            direction="row"
            sx={{
                height: "100%",
                alignItems: "space-evenly",
                justifyContent: "center",
                width: "100%",
                display: "flex",
            }}
            spacing={1}
        >
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    endpoint.champion_ratings({
                        role: 'TOP_LANE',
                        metrics: ['games'],
                        include_ally_perfs: false,
                        include_presence_stats: true,
                        ...props.paramObject
                    })
                }
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    endpoint.champion_ratings({
                        role: 'JUNGLE',
                        metrics: ['games'],
                        include_ally_perfs: false,
                        include_presence_stats: true,
                        ...props.paramObject
                    })
                }
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    endpoint.champion_ratings({
                        role: 'MID_LANE',
                        metrics: ['games'],
                        include_ally_perfs: false,
                        include_presence_stats: true,
                        ...props.paramObject
                    })
                }
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    endpoint.champion_ratings({
                        role: 'BOT_LANE',
                        metrics: ['games'],
                        include_ally_perfs: false,
                        include_presence_stats: true,
                        ...props.paramObject
                    })
                }
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    endpoint.champion_ratings({
                        role: 'UTILITY',
                        metrics: ['games'],
                        include_ally_perfs: false,
                        include_presence_stats: true,
                        ...props.paramObject
                    })
                }
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
        </Stack>
    ) : <></>
}


function DetailedTierlist(props: { paramObject: any }) {
    const api = useReferentialContext().farsightApi.computes;
    const [role, setRole] = useState("BOT_LANE");
    const [metrics, setMetrics] = useState<any[]>(useReferentialContext().selectedMetrics);
    const [paramObject, setParamObject] = useState<any>(props.paramObject as any);

    const cols = ["champion", "games", "rating", "winrate"];

    return (
        <Stack
            direction={"column"}
            sx={{
                height: "100%",
                justifyContent: "center",
                width: "100%",
                display: "flex",
                alignItems: "top",
            }}
            spacing={3}
        >
            <FilterSet paramObject={paramObject} setParamObject={setParamObject} target={targets.soloq} updateContext={false} role={role} setRole={setRole} metrics={metrics} setMetrics={setMetrics} />
            <TableDisplayGrid
                display_all={false}
                request={() =>
                    api.champion_ratings({
                        ...props.paramObject,
                        role: "UTILITY",
                        target: "soloq",
                    })
                }
                rowStyle={rowStyle.none}
                disp_columns={cols}
                sortBy={"rating"}
                paramObject={props.paramObject}
            />
        </Stack>
    );
}
