/* tslint:disable */
/* eslint-disable */
/**
 * Farsight-Alteration API
 * Backend API for Farsight-Alteration
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
    AgenciesVariableDistribution200ResponseInner,
    SoloqGameSummariesNA1,
    SoloqGameSummariesNA1Aggregate,
} from "../models/index";

export interface SoloqGameSummariesNA1AggregateRequest {
    groupby: Array<string>;
    ordering: Array<string>;
    account?: number;
    account_name?: string;
    additional_filters?: object;
    aggregates?: Array<string>;
    ally1?: string;
    ally2?: string;
    ally3?: string;
    ally4?: string;
    ally5?: string;
    ban?: string;
    champion?: string;
    champion_role_patch?: string;
    competitive_player?: string;
    date?: string;
    direct_matchup?: string;
    elo_as_total_lps_during_dame?: number;
    enemy1?: string;
    enemy2?: string;
    enemy3?: string;
    enemy4?: string;
    enemy5?: string;
    game_id?: string;
    having?: object;
    id?: string;
    include_in_performance?: boolean;
    index_on?: string;
    is_main_role?: boolean;
    is_played_by_otp?: boolean;
    item1?: string;
    item2?: string;
    item3?: string;
    keystone?: string;
    metrics?: Array<string>;
    participant_id?: number;
    patch?: string;
    performance?: number;
    relative_performance?: number;
    role?: string;
    server?: string;
    side?: string;
    skill_order1?: string;
    skill_order2?: string;
    skill_order3?: string;
    skill_order4?: string;
    skill_order5?: string;
    skill_order6?: string;
    subtree?: string;
    summoner1?: string;
    summoner2?: string;
    team_relative_performance?: number;
    teamwise_adjusted_relative_performance?: number;
    win?: boolean;
}

export interface SoloqGameSummariesNA1FieldValuesRequest {
    field: string;
    ordering: Array<string>;
    account?: number;
    account_name?: string;
    additional_filters?: object;
    ally1?: string;
    ally2?: string;
    ally3?: string;
    ally4?: string;
    ally5?: string;
    ban?: string;
    champion?: string;
    champion_role_patch?: string;
    competitive_player?: string;
    date?: string;
    direct_matchup?: string;
    elo_as_total_lps_during_dame?: number;
    enemy1?: string;
    enemy2?: string;
    enemy3?: string;
    enemy4?: string;
    enemy5?: string;
    game_id?: string;
    id?: string;
    include_in_performance?: boolean;
    is_main_role?: boolean;
    is_played_by_otp?: boolean;
    item1?: string;
    item2?: string;
    item3?: string;
    keystone?: string;
    participant_id?: number;
    patch?: string;
    performance?: number;
    relative_performance?: number;
    role?: string;
    server?: string;
    side?: string;
    skill_order1?: string;
    skill_order2?: string;
    skill_order3?: string;
    skill_order4?: string;
    skill_order5?: string;
    skill_order6?: string;
    subtree?: string;
    summoner1?: string;
    summoner2?: string;
    team_relative_performance?: number;
    teamwise_adjusted_relative_performance?: number;
    win?: boolean;
}

export interface SoloqGameSummariesNA1GetRequest {
    id: string;
}

export interface SoloqGameSummariesNA1ListRequest {
    account?: number;
    account_name?: string;
    additional_filters?: object;
    ally1?: string;
    ally2?: string;
    ally3?: string;
    ally4?: string;
    ally5?: string;
    annotations?: Array<string>;
    ban?: string;
    champion?: string;
    champion_role_patch?: string;
    competitive_player?: string;
    date?: string;
    direct_matchup?: string;
    elo_as_total_lps_during_dame?: number;
    enemy1?: string;
    enemy2?: string;
    enemy3?: string;
    enemy4?: string;
    enemy5?: string;
    game_id?: string;
    id?: string;
    include_in_performance?: boolean;
    is_main_role?: boolean;
    is_played_by_otp?: boolean;
    item1?: string;
    item2?: string;
    item3?: string;
    keystone?: string;
    ordering?: Array<string>;
    participant_id?: number;
    patch?: string;
    performance?: number;
    relative_performance?: number;
    role?: string;
    server?: string;
    side?: string;
    skill_order1?: string;
    skill_order2?: string;
    skill_order3?: string;
    skill_order4?: string;
    skill_order5?: string;
    skill_order6?: string;
    subtree?: string;
    summoner1?: string;
    summoner2?: string;
    team_relative_performance?: number;
    teamwise_adjusted_relative_performance?: number;
    win?: boolean;
}

export interface SoloqGameSummariesNA1VariableDistributionRequest {
    bucket_size: number;
    max: number;
    metric: string;
    min: number;
    account?: number;
    account_name?: string;
    additional_filters?: object;
    ally1?: string;
    ally2?: string;
    ally3?: string;
    ally4?: string;
    ally5?: string;
    ban?: string;
    champion?: string;
    champion_role_patch?: string;
    competitive_player?: string;
    date?: string;
    direct_matchup?: string;
    elo_as_total_lps_during_dame?: number;
    enemy1?: string;
    enemy2?: string;
    enemy3?: string;
    enemy4?: string;
    enemy5?: string;
    game_id?: string;
    id?: string;
    include_in_performance?: boolean;
    is_main_role?: boolean;
    is_played_by_otp?: boolean;
    item1?: string;
    item2?: string;
    item3?: string;
    keystone?: string;
    participant_id?: number;
    patch?: string;
    performance?: number;
    relative_performance?: number;
    role?: string;
    server?: string;
    side?: string;
    skill_order1?: string;
    skill_order2?: string;
    skill_order3?: string;
    skill_order4?: string;
    skill_order5?: string;
    skill_order6?: string;
    subtree?: string;
    summoner1?: string;
    summoner2?: string;
    team_relative_performance?: number;
    teamwise_adjusted_relative_performance?: number;
    win?: boolean;
}

/**
 *
 */
export class SoloqGameSummariesNA1Api extends runtime.BaseAPI {
    /**
     */
    async soloqGameSummariesNA1AggregateRaw(
        requestParameters: SoloqGameSummariesNA1AggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<SoloqGameSummariesNA1Aggregate>>> {
        if (requestParameters["groupby"] == null) {
            throw new runtime.RequiredError(
                "groupby",
                'Required parameter "groupby" was null or undefined when calling soloqGameSummariesNA1Aggregate().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling soloqGameSummariesNA1Aggregate().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["account"] != null) {
            queryParameters["account"] = requestParameters["account"];
        }

        if (requestParameters["account_name"] != null) {
            queryParameters["account_name"] = requestParameters["account_name"];
        }

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["aggregates"] != null) {
            queryParameters["aggregates"] = requestParameters["aggregates"];
        }

        if (requestParameters["ally1"] != null) {
            queryParameters["ally1"] = requestParameters["ally1"];
        }

        if (requestParameters["ally2"] != null) {
            queryParameters["ally2"] = requestParameters["ally2"];
        }

        if (requestParameters["ally3"] != null) {
            queryParameters["ally3"] = requestParameters["ally3"];
        }

        if (requestParameters["ally4"] != null) {
            queryParameters["ally4"] = requestParameters["ally4"];
        }

        if (requestParameters["ally5"] != null) {
            queryParameters["ally5"] = requestParameters["ally5"];
        }

        if (requestParameters["ban"] != null) {
            queryParameters["ban"] = requestParameters["ban"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["champion_role_patch"] != null) {
            queryParameters["champion_role_patch"] =
                requestParameters["champion_role_patch"];
        }

        if (requestParameters["competitive_player"] != null) {
            queryParameters["competitive_player"] =
                requestParameters["competitive_player"];
        }

        if (requestParameters["date"] != null) {
            queryParameters["date"] = requestParameters["date"];
        }

        if (requestParameters["direct_matchup"] != null) {
            queryParameters["direct_matchup"] =
                requestParameters["direct_matchup"];
        }

        if (requestParameters["elo_as_total_lps_during_dame"] != null) {
            queryParameters["elo_as_total_lps_during_dame"] =
                requestParameters["elo_as_total_lps_during_dame"];
        }

        if (requestParameters["enemy1"] != null) {
            queryParameters["enemy1"] = requestParameters["enemy1"];
        }

        if (requestParameters["enemy2"] != null) {
            queryParameters["enemy2"] = requestParameters["enemy2"];
        }

        if (requestParameters["enemy3"] != null) {
            queryParameters["enemy3"] = requestParameters["enemy3"];
        }

        if (requestParameters["enemy4"] != null) {
            queryParameters["enemy4"] = requestParameters["enemy4"];
        }

        if (requestParameters["enemy5"] != null) {
            queryParameters["enemy5"] = requestParameters["enemy5"];
        }

        if (requestParameters["game_id"] != null) {
            queryParameters["game_id"] = requestParameters["game_id"];
        }

        if (requestParameters["groupby"] != null) {
            queryParameters["groupby"] = requestParameters["groupby"];
        }

        if (requestParameters["having"] != null) {
            queryParameters["having"] = requestParameters["having"];
        }

        if (requestParameters["id"] != null) {
            queryParameters["id"] = requestParameters["id"];
        }

        if (requestParameters["include_in_performance"] != null) {
            queryParameters["include_in_performance"] =
                requestParameters["include_in_performance"];
        }

        if (requestParameters["index_on"] != null) {
            queryParameters["index_on"] = requestParameters["index_on"];
        }

        if (requestParameters["is_main_role"] != null) {
            queryParameters["is_main_role"] = requestParameters["is_main_role"];
        }

        if (requestParameters["is_played_by_otp"] != null) {
            queryParameters["is_played_by_otp"] =
                requestParameters["is_played_by_otp"];
        }

        if (requestParameters["item1"] != null) {
            queryParameters["item1"] = requestParameters["item1"];
        }

        if (requestParameters["item2"] != null) {
            queryParameters["item2"] = requestParameters["item2"];
        }

        if (requestParameters["item3"] != null) {
            queryParameters["item3"] = requestParameters["item3"];
        }

        if (requestParameters["keystone"] != null) {
            queryParameters["keystone"] = requestParameters["keystone"];
        }

        if (requestParameters["metrics"] != null) {
            queryParameters["metrics"] = requestParameters["metrics"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["participant_id"] != null) {
            queryParameters["participant_id"] =
                requestParameters["participant_id"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["performance"] != null) {
            queryParameters["performance"] = requestParameters["performance"];
        }

        if (requestParameters["relative_performance"] != null) {
            queryParameters["relative_performance"] =
                requestParameters["relative_performance"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["server"] != null) {
            queryParameters["server"] = requestParameters["server"];
        }

        if (requestParameters["side"] != null) {
            queryParameters["side"] = requestParameters["side"];
        }

        if (requestParameters["skill_order1"] != null) {
            queryParameters["skill_order1"] = requestParameters["skill_order1"];
        }

        if (requestParameters["skill_order2"] != null) {
            queryParameters["skill_order2"] = requestParameters["skill_order2"];
        }

        if (requestParameters["skill_order3"] != null) {
            queryParameters["skill_order3"] = requestParameters["skill_order3"];
        }

        if (requestParameters["skill_order4"] != null) {
            queryParameters["skill_order4"] = requestParameters["skill_order4"];
        }

        if (requestParameters["skill_order5"] != null) {
            queryParameters["skill_order5"] = requestParameters["skill_order5"];
        }

        if (requestParameters["skill_order6"] != null) {
            queryParameters["skill_order6"] = requestParameters["skill_order6"];
        }

        if (requestParameters["subtree"] != null) {
            queryParameters["subtree"] = requestParameters["subtree"];
        }

        if (requestParameters["summoner1"] != null) {
            queryParameters["summoner1"] = requestParameters["summoner1"];
        }

        if (requestParameters["summoner2"] != null) {
            queryParameters["summoner2"] = requestParameters["summoner2"];
        }

        if (requestParameters["team_relative_performance"] != null) {
            queryParameters["team_relative_performance"] =
                requestParameters["team_relative_performance"];
        }

        if (
            requestParameters["teamwise_adjusted_relative_performance"] != null
        ) {
            queryParameters["teamwise_adjusted_relative_performance"] =
                requestParameters["teamwise_adjusted_relative_performance"];
        }

        if (requestParameters["win"] != null) {
            queryParameters["win"] = requestParameters["win"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/SoloqGameSummaries/NA1/operations/aggregate`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async aggregate(
        requestParameters: SoloqGameSummariesNA1AggregateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<SoloqGameSummariesNA1Aggregate>> {
        const response = await this.soloqGameSummariesNA1AggregateRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async soloqGameSummariesNA1FieldValuesRaw(
        requestParameters: SoloqGameSummariesNA1FieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters["field"] == null) {
            throw new runtime.RequiredError(
                "field",
                'Required parameter "field" was null or undefined when calling soloqGameSummariesNA1FieldValues().',
            );
        }

        if (requestParameters["ordering"] == null) {
            throw new runtime.RequiredError(
                "ordering",
                'Required parameter "ordering" was null or undefined when calling soloqGameSummariesNA1FieldValues().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["account"] != null) {
            queryParameters["account"] = requestParameters["account"];
        }

        if (requestParameters["account_name"] != null) {
            queryParameters["account_name"] = requestParameters["account_name"];
        }

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["ally1"] != null) {
            queryParameters["ally1"] = requestParameters["ally1"];
        }

        if (requestParameters["ally2"] != null) {
            queryParameters["ally2"] = requestParameters["ally2"];
        }

        if (requestParameters["ally3"] != null) {
            queryParameters["ally3"] = requestParameters["ally3"];
        }

        if (requestParameters["ally4"] != null) {
            queryParameters["ally4"] = requestParameters["ally4"];
        }

        if (requestParameters["ally5"] != null) {
            queryParameters["ally5"] = requestParameters["ally5"];
        }

        if (requestParameters["ban"] != null) {
            queryParameters["ban"] = requestParameters["ban"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["champion_role_patch"] != null) {
            queryParameters["champion_role_patch"] =
                requestParameters["champion_role_patch"];
        }

        if (requestParameters["competitive_player"] != null) {
            queryParameters["competitive_player"] =
                requestParameters["competitive_player"];
        }

        if (requestParameters["date"] != null) {
            queryParameters["date"] = requestParameters["date"];
        }

        if (requestParameters["direct_matchup"] != null) {
            queryParameters["direct_matchup"] =
                requestParameters["direct_matchup"];
        }

        if (requestParameters["elo_as_total_lps_during_dame"] != null) {
            queryParameters["elo_as_total_lps_during_dame"] =
                requestParameters["elo_as_total_lps_during_dame"];
        }

        if (requestParameters["enemy1"] != null) {
            queryParameters["enemy1"] = requestParameters["enemy1"];
        }

        if (requestParameters["enemy2"] != null) {
            queryParameters["enemy2"] = requestParameters["enemy2"];
        }

        if (requestParameters["enemy3"] != null) {
            queryParameters["enemy3"] = requestParameters["enemy3"];
        }

        if (requestParameters["enemy4"] != null) {
            queryParameters["enemy4"] = requestParameters["enemy4"];
        }

        if (requestParameters["enemy5"] != null) {
            queryParameters["enemy5"] = requestParameters["enemy5"];
        }

        if (requestParameters["field"] != null) {
            queryParameters["field"] = requestParameters["field"];
        }

        if (requestParameters["game_id"] != null) {
            queryParameters["game_id"] = requestParameters["game_id"];
        }

        if (requestParameters["id"] != null) {
            queryParameters["id"] = requestParameters["id"];
        }

        if (requestParameters["include_in_performance"] != null) {
            queryParameters["include_in_performance"] =
                requestParameters["include_in_performance"];
        }

        if (requestParameters["is_main_role"] != null) {
            queryParameters["is_main_role"] = requestParameters["is_main_role"];
        }

        if (requestParameters["is_played_by_otp"] != null) {
            queryParameters["is_played_by_otp"] =
                requestParameters["is_played_by_otp"];
        }

        if (requestParameters["item1"] != null) {
            queryParameters["item1"] = requestParameters["item1"];
        }

        if (requestParameters["item2"] != null) {
            queryParameters["item2"] = requestParameters["item2"];
        }

        if (requestParameters["item3"] != null) {
            queryParameters["item3"] = requestParameters["item3"];
        }

        if (requestParameters["keystone"] != null) {
            queryParameters["keystone"] = requestParameters["keystone"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["participant_id"] != null) {
            queryParameters["participant_id"] =
                requestParameters["participant_id"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["performance"] != null) {
            queryParameters["performance"] = requestParameters["performance"];
        }

        if (requestParameters["relative_performance"] != null) {
            queryParameters["relative_performance"] =
                requestParameters["relative_performance"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["server"] != null) {
            queryParameters["server"] = requestParameters["server"];
        }

        if (requestParameters["side"] != null) {
            queryParameters["side"] = requestParameters["side"];
        }

        if (requestParameters["skill_order1"] != null) {
            queryParameters["skill_order1"] = requestParameters["skill_order1"];
        }

        if (requestParameters["skill_order2"] != null) {
            queryParameters["skill_order2"] = requestParameters["skill_order2"];
        }

        if (requestParameters["skill_order3"] != null) {
            queryParameters["skill_order3"] = requestParameters["skill_order3"];
        }

        if (requestParameters["skill_order4"] != null) {
            queryParameters["skill_order4"] = requestParameters["skill_order4"];
        }

        if (requestParameters["skill_order5"] != null) {
            queryParameters["skill_order5"] = requestParameters["skill_order5"];
        }

        if (requestParameters["skill_order6"] != null) {
            queryParameters["skill_order6"] = requestParameters["skill_order6"];
        }

        if (requestParameters["subtree"] != null) {
            queryParameters["subtree"] = requestParameters["subtree"];
        }

        if (requestParameters["summoner1"] != null) {
            queryParameters["summoner1"] = requestParameters["summoner1"];
        }

        if (requestParameters["summoner2"] != null) {
            queryParameters["summoner2"] = requestParameters["summoner2"];
        }

        if (requestParameters["team_relative_performance"] != null) {
            queryParameters["team_relative_performance"] =
                requestParameters["team_relative_performance"];
        }

        if (
            requestParameters["teamwise_adjusted_relative_performance"] != null
        ) {
            queryParameters["teamwise_adjusted_relative_performance"] =
                requestParameters["teamwise_adjusted_relative_performance"];
        }

        if (requestParameters["win"] != null) {
            queryParameters["win"] = requestParameters["win"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/SoloqGameSummaries/NA1/operations/field-values`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async field_values(
        requestParameters: SoloqGameSummariesNA1FieldValuesRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<string>> {
        const response = await this.soloqGameSummariesNA1FieldValuesRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async soloqGameSummariesNA1GetRaw(
        requestParameters: SoloqGameSummariesNA1GetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<SoloqGameSummariesNA1>> {
        if (requestParameters["id"] == null) {
            throw new runtime.RequiredError(
                "id",
                'Required parameter "id" was null or undefined when calling soloqGameSummariesNA1Get().',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/SoloqGameSummaries/NA1/item/{id}`.replace(
                    `{${"id"}}`,
                    encodeURIComponent(String(requestParameters["id"])),
                ),
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async get(
        requestParameters: SoloqGameSummariesNA1GetRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<SoloqGameSummariesNA1> {
        const response = await this.soloqGameSummariesNA1GetRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async soloqGameSummariesNA1ListRaw(
        requestParameters: SoloqGameSummariesNA1ListRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<SoloqGameSummariesNA1>>> {
        const queryParameters: any = {};

        if (requestParameters["account"] != null) {
            queryParameters["account"] = requestParameters["account"];
        }

        if (requestParameters["account_name"] != null) {
            queryParameters["account_name"] = requestParameters["account_name"];
        }

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["ally1"] != null) {
            queryParameters["ally1"] = requestParameters["ally1"];
        }

        if (requestParameters["ally2"] != null) {
            queryParameters["ally2"] = requestParameters["ally2"];
        }

        if (requestParameters["ally3"] != null) {
            queryParameters["ally3"] = requestParameters["ally3"];
        }

        if (requestParameters["ally4"] != null) {
            queryParameters["ally4"] = requestParameters["ally4"];
        }

        if (requestParameters["ally5"] != null) {
            queryParameters["ally5"] = requestParameters["ally5"];
        }

        if (requestParameters["annotations"] != null) {
            queryParameters["annotations"] = requestParameters["annotations"];
        }

        if (requestParameters["ban"] != null) {
            queryParameters["ban"] = requestParameters["ban"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["champion_role_patch"] != null) {
            queryParameters["champion_role_patch"] =
                requestParameters["champion_role_patch"];
        }

        if (requestParameters["competitive_player"] != null) {
            queryParameters["competitive_player"] =
                requestParameters["competitive_player"];
        }

        if (requestParameters["date"] != null) {
            queryParameters["date"] = requestParameters["date"];
        }

        if (requestParameters["direct_matchup"] != null) {
            queryParameters["direct_matchup"] =
                requestParameters["direct_matchup"];
        }

        if (requestParameters["elo_as_total_lps_during_dame"] != null) {
            queryParameters["elo_as_total_lps_during_dame"] =
                requestParameters["elo_as_total_lps_during_dame"];
        }

        if (requestParameters["enemy1"] != null) {
            queryParameters["enemy1"] = requestParameters["enemy1"];
        }

        if (requestParameters["enemy2"] != null) {
            queryParameters["enemy2"] = requestParameters["enemy2"];
        }

        if (requestParameters["enemy3"] != null) {
            queryParameters["enemy3"] = requestParameters["enemy3"];
        }

        if (requestParameters["enemy4"] != null) {
            queryParameters["enemy4"] = requestParameters["enemy4"];
        }

        if (requestParameters["enemy5"] != null) {
            queryParameters["enemy5"] = requestParameters["enemy5"];
        }

        if (requestParameters["game_id"] != null) {
            queryParameters["game_id"] = requestParameters["game_id"];
        }

        if (requestParameters["id"] != null) {
            queryParameters["id"] = requestParameters["id"];
        }

        if (requestParameters["include_in_performance"] != null) {
            queryParameters["include_in_performance"] =
                requestParameters["include_in_performance"];
        }

        if (requestParameters["is_main_role"] != null) {
            queryParameters["is_main_role"] = requestParameters["is_main_role"];
        }

        if (requestParameters["is_played_by_otp"] != null) {
            queryParameters["is_played_by_otp"] =
                requestParameters["is_played_by_otp"];
        }

        if (requestParameters["item1"] != null) {
            queryParameters["item1"] = requestParameters["item1"];
        }

        if (requestParameters["item2"] != null) {
            queryParameters["item2"] = requestParameters["item2"];
        }

        if (requestParameters["item3"] != null) {
            queryParameters["item3"] = requestParameters["item3"];
        }

        if (requestParameters["keystone"] != null) {
            queryParameters["keystone"] = requestParameters["keystone"];
        }

        if (requestParameters["ordering"] != null) {
            queryParameters["ordering"] = requestParameters["ordering"];
        }

        if (requestParameters["participant_id"] != null) {
            queryParameters["participant_id"] =
                requestParameters["participant_id"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["performance"] != null) {
            queryParameters["performance"] = requestParameters["performance"];
        }

        if (requestParameters["relative_performance"] != null) {
            queryParameters["relative_performance"] =
                requestParameters["relative_performance"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["server"] != null) {
            queryParameters["server"] = requestParameters["server"];
        }

        if (requestParameters["side"] != null) {
            queryParameters["side"] = requestParameters["side"];
        }

        if (requestParameters["skill_order1"] != null) {
            queryParameters["skill_order1"] = requestParameters["skill_order1"];
        }

        if (requestParameters["skill_order2"] != null) {
            queryParameters["skill_order2"] = requestParameters["skill_order2"];
        }

        if (requestParameters["skill_order3"] != null) {
            queryParameters["skill_order3"] = requestParameters["skill_order3"];
        }

        if (requestParameters["skill_order4"] != null) {
            queryParameters["skill_order4"] = requestParameters["skill_order4"];
        }

        if (requestParameters["skill_order5"] != null) {
            queryParameters["skill_order5"] = requestParameters["skill_order5"];
        }

        if (requestParameters["skill_order6"] != null) {
            queryParameters["skill_order6"] = requestParameters["skill_order6"];
        }

        if (requestParameters["subtree"] != null) {
            queryParameters["subtree"] = requestParameters["subtree"];
        }

        if (requestParameters["summoner1"] != null) {
            queryParameters["summoner1"] = requestParameters["summoner1"];
        }

        if (requestParameters["summoner2"] != null) {
            queryParameters["summoner2"] = requestParameters["summoner2"];
        }

        if (requestParameters["team_relative_performance"] != null) {
            queryParameters["team_relative_performance"] =
                requestParameters["team_relative_performance"];
        }

        if (
            requestParameters["teamwise_adjusted_relative_performance"] != null
        ) {
            queryParameters["teamwise_adjusted_relative_performance"] =
                requestParameters["teamwise_adjusted_relative_performance"];
        }

        if (requestParameters["win"] != null) {
            queryParameters["win"] = requestParameters["win"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/SoloqGameSummaries/NA1/list`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async list(
        requestParameters: SoloqGameSummariesNA1ListRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<SoloqGameSummariesNA1>> {
        const response = await this.soloqGameSummariesNA1ListRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async soloqGameSummariesNA1VariableDistributionRaw(
        requestParameters: SoloqGameSummariesNA1VariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<
        runtime.ApiResponse<Array<AgenciesVariableDistribution200ResponseInner>>
    > {
        if (requestParameters["bucket_size"] == null) {
            throw new runtime.RequiredError(
                "bucket_size",
                'Required parameter "bucket_size" was null or undefined when calling soloqGameSummariesNA1VariableDistribution().',
            );
        }

        if (requestParameters["max"] == null) {
            throw new runtime.RequiredError(
                "max",
                'Required parameter "max" was null or undefined when calling soloqGameSummariesNA1VariableDistribution().',
            );
        }

        if (requestParameters["metric"] == null) {
            throw new runtime.RequiredError(
                "metric",
                'Required parameter "metric" was null or undefined when calling soloqGameSummariesNA1VariableDistribution().',
            );
        }

        if (requestParameters["min"] == null) {
            throw new runtime.RequiredError(
                "min",
                'Required parameter "min" was null or undefined when calling soloqGameSummariesNA1VariableDistribution().',
            );
        }

        const queryParameters: any = {};

        if (requestParameters["account"] != null) {
            queryParameters["account"] = requestParameters["account"];
        }

        if (requestParameters["account_name"] != null) {
            queryParameters["account_name"] = requestParameters["account_name"];
        }

        if (requestParameters["additional_filters"] != null) {
            queryParameters["additional_filters"] =
                requestParameters["additional_filters"];
        }

        if (requestParameters["ally1"] != null) {
            queryParameters["ally1"] = requestParameters["ally1"];
        }

        if (requestParameters["ally2"] != null) {
            queryParameters["ally2"] = requestParameters["ally2"];
        }

        if (requestParameters["ally3"] != null) {
            queryParameters["ally3"] = requestParameters["ally3"];
        }

        if (requestParameters["ally4"] != null) {
            queryParameters["ally4"] = requestParameters["ally4"];
        }

        if (requestParameters["ally5"] != null) {
            queryParameters["ally5"] = requestParameters["ally5"];
        }

        if (requestParameters["ban"] != null) {
            queryParameters["ban"] = requestParameters["ban"];
        }

        if (requestParameters["bucket_size"] != null) {
            queryParameters["bucket_size"] = requestParameters["bucket_size"];
        }

        if (requestParameters["champion"] != null) {
            queryParameters["champion"] = requestParameters["champion"];
        }

        if (requestParameters["champion_role_patch"] != null) {
            queryParameters["champion_role_patch"] =
                requestParameters["champion_role_patch"];
        }

        if (requestParameters["competitive_player"] != null) {
            queryParameters["competitive_player"] =
                requestParameters["competitive_player"];
        }

        if (requestParameters["date"] != null) {
            queryParameters["date"] = requestParameters["date"];
        }

        if (requestParameters["direct_matchup"] != null) {
            queryParameters["direct_matchup"] =
                requestParameters["direct_matchup"];
        }

        if (requestParameters["elo_as_total_lps_during_dame"] != null) {
            queryParameters["elo_as_total_lps_during_dame"] =
                requestParameters["elo_as_total_lps_during_dame"];
        }

        if (requestParameters["enemy1"] != null) {
            queryParameters["enemy1"] = requestParameters["enemy1"];
        }

        if (requestParameters["enemy2"] != null) {
            queryParameters["enemy2"] = requestParameters["enemy2"];
        }

        if (requestParameters["enemy3"] != null) {
            queryParameters["enemy3"] = requestParameters["enemy3"];
        }

        if (requestParameters["enemy4"] != null) {
            queryParameters["enemy4"] = requestParameters["enemy4"];
        }

        if (requestParameters["enemy5"] != null) {
            queryParameters["enemy5"] = requestParameters["enemy5"];
        }

        if (requestParameters["game_id"] != null) {
            queryParameters["game_id"] = requestParameters["game_id"];
        }

        if (requestParameters["id"] != null) {
            queryParameters["id"] = requestParameters["id"];
        }

        if (requestParameters["include_in_performance"] != null) {
            queryParameters["include_in_performance"] =
                requestParameters["include_in_performance"];
        }

        if (requestParameters["is_main_role"] != null) {
            queryParameters["is_main_role"] = requestParameters["is_main_role"];
        }

        if (requestParameters["is_played_by_otp"] != null) {
            queryParameters["is_played_by_otp"] =
                requestParameters["is_played_by_otp"];
        }

        if (requestParameters["item1"] != null) {
            queryParameters["item1"] = requestParameters["item1"];
        }

        if (requestParameters["item2"] != null) {
            queryParameters["item2"] = requestParameters["item2"];
        }

        if (requestParameters["item3"] != null) {
            queryParameters["item3"] = requestParameters["item3"];
        }

        if (requestParameters["keystone"] != null) {
            queryParameters["keystone"] = requestParameters["keystone"];
        }

        if (requestParameters["max"] != null) {
            queryParameters["max"] = requestParameters["max"];
        }

        if (requestParameters["metric"] != null) {
            queryParameters["metric"] = requestParameters["metric"];
        }

        if (requestParameters["min"] != null) {
            queryParameters["min"] = requestParameters["min"];
        }

        if (requestParameters["participant_id"] != null) {
            queryParameters["participant_id"] =
                requestParameters["participant_id"];
        }

        if (requestParameters["patch"] != null) {
            queryParameters["patch"] = requestParameters["patch"];
        }

        if (requestParameters["performance"] != null) {
            queryParameters["performance"] = requestParameters["performance"];
        }

        if (requestParameters["relative_performance"] != null) {
            queryParameters["relative_performance"] =
                requestParameters["relative_performance"];
        }

        if (requestParameters["role"] != null) {
            queryParameters["role"] = requestParameters["role"];
        }

        if (requestParameters["server"] != null) {
            queryParameters["server"] = requestParameters["server"];
        }

        if (requestParameters["side"] != null) {
            queryParameters["side"] = requestParameters["side"];
        }

        if (requestParameters["skill_order1"] != null) {
            queryParameters["skill_order1"] = requestParameters["skill_order1"];
        }

        if (requestParameters["skill_order2"] != null) {
            queryParameters["skill_order2"] = requestParameters["skill_order2"];
        }

        if (requestParameters["skill_order3"] != null) {
            queryParameters["skill_order3"] = requestParameters["skill_order3"];
        }

        if (requestParameters["skill_order4"] != null) {
            queryParameters["skill_order4"] = requestParameters["skill_order4"];
        }

        if (requestParameters["skill_order5"] != null) {
            queryParameters["skill_order5"] = requestParameters["skill_order5"];
        }

        if (requestParameters["skill_order6"] != null) {
            queryParameters["skill_order6"] = requestParameters["skill_order6"];
        }

        if (requestParameters["subtree"] != null) {
            queryParameters["subtree"] = requestParameters["subtree"];
        }

        if (requestParameters["summoner1"] != null) {
            queryParameters["summoner1"] = requestParameters["summoner1"];
        }

        if (requestParameters["summoner2"] != null) {
            queryParameters["summoner2"] = requestParameters["summoner2"];
        }

        if (requestParameters["team_relative_performance"] != null) {
            queryParameters["team_relative_performance"] =
                requestParameters["team_relative_performance"];
        }

        if (
            requestParameters["teamwise_adjusted_relative_performance"] != null
        ) {
            queryParameters["teamwise_adjusted_relative_performance"] =
                requestParameters["teamwise_adjusted_relative_performance"];
        }

        if (requestParameters["win"] != null) {
            queryParameters["win"] = requestParameters["win"];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] =
                await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request(
            {
                path: `/SoloqGameSummaries/NA1/operations/variable-distribution`,
                method: "GET",
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async variable_distribution(
        requestParameters: SoloqGameSummariesNA1VariableDistributionRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<AgenciesVariableDistribution200ResponseInner>> {
        const response =
            await this.soloqGameSummariesNA1VariableDistributionRaw(
                requestParameters,
                initOverrides,
            );
        return await response.value();
    }
}
