import {SoloQAccounts} from "../../farsight-api";
import {useReferentialContext} from "../../Context";
import {Link, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";


export default function OpggIcon(props: {account: SoloQAccounts, sx? :any}){
    return <Tooltip title={"op.gg"} followCursor={true} sx={{ display: 'inline-flex', alignItems: 'center' }}>
                                            <Link href={"https://www.op.gg/summoners/" + props.account.server.replace("1", "") + "/" + props.account.account_name} target="_blank">
                                                <Box
                                                    component={"img"}
                                                    src="../icons/opgg.ico"
                                                    alt="op.gg"
                                                    sx={props.sx ? props.sx : {
                                                        width: "25px",
                                                        height: "25px",
                                                    }}
                                                />
                                            </Link>
                                        </Tooltip>

}