import {useReferentialContext} from "../../Context";
import {
    Alert,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Snackbar,
    Stack,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {ClientOrganization, User} from "../../farsight-api";
import WindowContainer from "../../components/layout/WindowContainer";
import {Email} from "@mui/icons-material";
import StyledDatagrid, {ColumnTypes} from "../../components/datagrids/styled_datagrid";
import {GridCellEditStopParams, GridColDef} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import TeamIcon from "../../components/Icons/TeamIcon";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";


export default function ManageOrg(){

    return <WindowContainer direction={'row'} spacing={2}>
        <Box sx={{width:'50%'}}>
            <UsersManager/>
        </Box>
        <Box sx={{width:'50%'}}>
            <ClientOrganizationManager/>
        </Box>
    </WindowContainer>
}

function ClientOrganizationManager(){
    const ctx = useReferentialContext();
    const [organization, setOrg] = useState(null as null|ClientOrganization);

    useEffect(() => {
        ctx.farsightApi.clientOrganization.get({id: ctx.user.organization}).then(org => setOrg(org))
    }, []);
    // const organization
    return organization ? <Stack direction={'column'} spacing={1}>
        <Stack direction={'row'} spacing={3}>
            <TeamIcon team_name={organization.id}/>
            <Typography variant={'h4'} sx={{align:'center',justify:'center',display:'flex', fontWeight:'bold'}}>{organization.id}</Typography>
        </Stack>
        <Typography variant={'h5'} sx={{align:'center',justify:'center',display:'flex', fontWeight:'bold'}}>Subscribed until {organization.subscription_ends} on {organization.plan} plan</Typography>
        <Typography variant={'h5'} sx={{fontWeight:'bold'}}>Grid Key : {organization.grid_api_key}</Typography>
        <Typography variant={'h4'} sx={{align:'center',justify:'center',display:'flex', fontWeight:'bold'}}>Teams in organization</Typography>
        <Stack direction={'column'} spacing={1}>
        {
            organization.teams.split(',').map(t => {
                return <Stack direction={'row'} spacing={3}>
                    <TeamIcon team_name={t}/>
                    <Typography variant={'h4'} sx={{align:'center',justify:'center',display:'flex', fontWeight:'bold'}}>{t}</Typography>
                </Stack>
            })
        }
        </Stack>
    </Stack> : <Typography variant={"h3"}>Unauthorized</Typography>
}

function UsersManager(){
    const ctx = useReferentialContext();
    const [users, setUsers] = useState([] as User[]);
    const [isLoading, setLoading] = useState(false);
    const [createUserOpen, setCreateUserOpen] = useState(false);
    const reload = () => {
        setLoading(true)
        ctx.farsightApi.users.list({ordering:['team','-is_staff']}).then(r => {
            setUsers(r);
            setLoading(false);
        })
    }

    useEffect(() => {
        reload()
    }, []);

    const grid_columns = [
        ColumnTypes.string("username", "U.", {maxWidth:200}),
        {
            headerName: "Team",
            field: "team_id",
            align: "center",
            minWidth: 250,
            maxWidth: 250,
            headerAlign: "center",
            flex: 1,
            editable: true,
        } as GridColDef,
        {
            headerName: "Email",
            field: "email",
            align: "center",
            minWidth: 250,
            maxWidth: 250,
            headerAlign: "center",
            flex: 1,
            editable: true,
        } as GridColDef,
        {
            headerName: "Staff",
            field: "is_staff",
            align: "center",
            headerAlign: "center",
            minWidth: 90,
            maxWidth: 90,
            flex: 1,
            renderCell: (params: any) => (
                <Checkbox
                    checked={params.value}
                    onClick={() => {
                        ctx.farsightApi.users.patch({
                            id: params.row.id,
                            patched_user : {
                                is_staff: !params.value
                            }
                        }).then(() => reload())
                    }}
                />
            ),
        } as GridColDef,
        // {
        //     headerName: "Active",
        //     field: "is_active",
        //     align: "center",
        //     headerAlign: "center",
        //     minWidth: 90,
        //     maxWidth: 90,
        //     flex: 1,
        //     renderCell: (params: any) => (
        //         <Checkbox
        //             checked={params.value}
        //             onClick={() => {
        //                 ctx.farsightApi.users.patch({
        //                     id: params.row.id,
        //                     patched_user : {
        //                         is_active: !params.value
        //                     }
        //                 }).then(() => reload())
        //             }}
        //         />
        //     ),
        // } as GridColDef,
        {
            headerName: "Delete",
            field: "id",
            align: "center",
            headerAlign: "center",
            maxWidth: 90,
            flex: 1,
            renderCell: (params: any) => (
                <Button
                    onClick={() => {
                        ctx.farsightApi.users.delete({
                            id: params.row.id
                        }).then(() => ctx.farsightApi.users.list().then(r => setUsers(r)))
                    }}
                >
                    <DeleteIcon />
                </Button>
            ),
        } as GridColDef,
    ]

    return (<Stack direction={'column'} sx={{height:'100%', width:'100%', display:'flex', align:'left', justify:'left'}} spacing={1}>
            <Button onClick={() => setCreateUserOpen(true)} variant={'outlined'} sx={{width:'100px'}}>Create User</Button>
            <CreateUserDialog open={createUserOpen} setOpen={setCreateUserOpen}/>
        <StyledDatagrid
            autoPageSize
            rowHeight={65}
            sx={{
                width: "100%",
                "& .header": {
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                },
                "& .border-left": {
                    borderLeft: "solid grey",
                    borderWidth: "2px",
                },
            }}
            getRowId={(row) => row.id}
            initialState={{
                sorting: {
                    sortModel: [
                        { field: "team", sort: "desc" },
                        { field: "is_staff", sort: "desc" }
                    ],
                },
            }}
            loading={isLoading}
            columns={grid_columns}
            rows={users}
            hideFooter={false}
            onCellEditStop={(params: GridCellEditStopParams, event: any) => {
                const request_params = {
                    id: params.row.id,
                    patched_user : {}
                } as any;
                request_params.patched_user[params.field] = event.target.value;
                ctx.farsightApi.users.patch(request_params).then(() => reload())
            }}
        />
        </Stack>
    );
}

function CreateUserDialog(props: {open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>>}) {
  const ctx = useReferentialContext();
  const [formData, setFormData] = useState({
    username: "",
    team: "",
    email: "",
    is_staff: false,
  });

  // Snackbar state
  const [toast, setToast] = useState({
    open: false,
    message: "",
    severity: "success", // Options: success, error, warning, info
  });



  const handleClose = () => props.setOpen(false);

  // Handle form changes
  const handleInputChange = (e: any) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Submit form
  const handleSubmit = async () => {
      ctx.farsightApi.users.create({id: 'random', user: formData as User}).then(
          r => {handleClose()
                  setToast({
                    open: true,
                    message: `User created successfully. Password: ${r.password}`,
                    severity: "success",
                  });
                }).catch(error =>
                  setToast({
                    open: true,
                    message: `Error: ${error.message}`,
                    severity: "error",
                  })
      )
    };

  // Close Snackbar
  const handleToastClose = () => setToast((prevToast: any) => ({ ...prevToast, open: false }));

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle>Create New User</DialogTitle>
        <DialogContent>
          <TextField
            label="Username"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
          />
          <TextField
            label="Team"
            name="team"
            value={formData.team}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
          />
          <TextField
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
          />
          <FormControlLabel
                control={
                  <Checkbox
                    name="is_staff"
                    checked={formData.is_staff}
                    onChange={(e) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        is_staff: e.target.checked,
                      }))
                    }
                  />
                }
                label="Is Staff"
              />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Validate
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={toast.open}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleToastClose} severity={toast.severity as any} variant="filled">
          {toast.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
