import React, { useState, useEffect, useRef, useCallback } from "react";
import {Fade, Slide, Stack, StackProps} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {ExpandLess, ExpandMore} from "@mui/icons-material";

interface PaginatedStackProps extends StackProps {
    children: React.ReactNode[];
}

const PaginatedStack: React.FC<PaginatedStackProps> = ({ children, direction = "row", spacing = 2, ...props }) => {
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);
    const [lastDirectionClicked, setLastDirectionClicked] = useState('left' as "right" | "left" | "up" | "down" | undefined);
    const [animate, setAnimate] = useState(true);
    console.log(props.sx)
    // Handle resizing and recalculating items per page
    const updateItemsPerPage = useCallback(() => {
        if (containerRef.current) {
            const containerWidth = containerRef.current.clientWidth;
            const containerHeight = containerRef.current.clientHeight;

            let totalAvailableSpace = direction === "row" ? containerWidth : containerHeight;

            // Estimate the size of the chevron arrows
            const chevronSize = 40; // Approximate size for chevrons
            totalAvailableSpace -= (page > 1 ? chevronSize : 0) + chevronSize; // Reduce for chevrons

            console.log(children)
            // Estimate the size of the first child to determine how many items fit
            const childSize : string = direction === "row" ? (children[0] as any)?.props?.sx?.width || 0 : (children[0] as any)?.props?.sx?.width || 0;
            console.log(childSize)
            if (childSize.endsWith && childSize.endsWith('px')) {
                setItemsPerPage(Math.floor(totalAvailableSpace / parseInt(childSize.replace('px',''))));
            }
        }
    }, [children, direction, page]);

    useEffect(() => {
        // if (containerRef.current) {
        //     setAnimate(false);
        //     setTimeout(() => {setAnimate(true)}, 10);
        // }
        updateItemsPerPage();
        window.addEventListener("resize", updateItemsPerPage);
        return () => window.removeEventListener("resize", updateItemsPerPage);

    }, [updateItemsPerPage]);

    const totalPages = Math.ceil(children.length / itemsPerPage);
    console.log(itemsPerPage)
    // Handlers for page navigation
    const handleNextPage = () => {
        if(direction==='row'){
            setLastDirectionClicked('left')
        }else{
            setLastDirectionClicked('up')
        }
        if (page < totalPages) setPage(page + 1);
        setAnimate(false);
        setTimeout(() => {
            setAnimate(true)
        }, 10);
    };

    const handlePrevPage = () => {
        if(direction==='row'){
            setLastDirectionClicked('right')
        }else{
            setLastDirectionClicked('down')
        }
        if (page > 1) setPage(page - 1);
        setAnimate(false);
        setTimeout(() => {
            setAnimate(true)
        }, 10);
    };

    // Render children for the current page
    const startIdx = (page - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    const paginatedChildren = children.slice(startIdx, endIdx);

    return (
            <Stack
                direction={direction}
                spacing={spacing}
                ref={containerRef}
                {...props}
                sx={{
                    overflow: "hidden",
                    position: "relative", // Important for Slide to animate within bounds
                    ...props.sx
                }}
            >
                {page > 1 ? (direction === "row" ? <ChevronLeftIcon sx={{cursor:'pointer'}} onClick={handlePrevPage} /> : <ExpandLess sx={{cursor:'pointer'}} onClick={handlePrevPage} />) : <></>}
                <Slide direction={lastDirectionClicked} in={animate} exit={false} container={containerRef.current}>
                    <Stack direction={direction} spacing={spacing} sx={{width:'100%', height:'100%', overflow:'hidden',
                        alignItems: (props as any).sx?.alignItems ? (props as any).sx?.alignItems : 'center',
                        justifyContent: (props as any).sx?.justifyContent ? (props as any).sx?.justifyContent : 'center',
                        display:'flex'}}
                    >
                        {paginatedChildren}
                    </Stack>
                </Slide>

                {page < totalPages ? ( direction === "row" ? <ChevronRightIcon sx={{cursor:'pointer'}} onClick={handleNextPage} /> : <ExpandMore sx={{cursor:'pointer'}} onClick={handleNextPage} />) : <></>}
            </Stack>
    );
};

export default PaginatedStack;
